@charset "UTF-8";
.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body, html {
  background-color: #f5f5f5;
  overflow-x: hidden;
  min-width: 320px; }
  @media (max-width: 319px) {
    body, html {
      overflow: auto; } }

ul {
  list-style: none; }

ol {
  padding-left: 18px; }

* {
  box-sizing: border-box;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  color: #141414; }

button, input, select, textarea {
  border: 0;
  outline: 0; }

a {
  text-decoration: none; }

header, main, footer {
  width: 100%; }

.viewport-wrapper {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 16px; }

img {
  max-width: 100%; }

hr {
  border: 0;
  border-top: 1px solid #ccc; }

@media (min-width: 1025px) {
  .form, .bar-list {
    display: block !important; } }

@media (min-width: 1241px) {
  .nav__toggle {
    display: block !important; } }

.inactive {
  display: none; }

.ava {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-top: 4px; }
  @media (min-width: 1025px) {
    .ava {
      margin-top: 22px;
      display: block;
      float: left;
      margin-right: 8px; } }

.fa-after {
  display: block;
  float: right;
  margin-left: 5px;
  margin-top: 8px; }

.status1, .status2, .status3, .status4, .status5 {
  width: 12px;
  height: 12px;
  display: inline-block; }

.right {
  float: right; }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.l-toolbar {
  width: 100%;
  height: 70px;
  border-bottom: thin solid #ddd;
  background-color: #f5f5f5; }
  .l-toolbar .viewport-wrapper {
    display: flex;
    justify-content: space-between; }
  .l-toolbar__item {
    display: block;
    height: 100%;
    padding: 22px 0;
    float: left; }
    .l-toolbar__item span, .l-toolbar__item i {
      transition: color 0.2s;
      display: block;
      float: left;
      line-height: 24px;
      padding: 0 4px; }
      @media (max-width: 620px) {
        .l-toolbar__item span, .l-toolbar__item i {
          font-size: 0.875rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100px; } }
    .l-toolbar__item i {
      margin-top: 1px; }
    .l-toolbar__item img {
      float: left; }
    .l-toolbar__item:hover span, .l-toolbar__item:hover i, .l-toolbar__item:focus span, .l-toolbar__item:focus i {
      color: red; }
  .l-toolbar--left, .l-toolbar--right {
    height: 100%; }

@media (max-width: 1024px) {
  .l-toolbar {
    width: 100%;
    height: 32px; }
    .l-toolbar__item {
      padding: 4px 0; }
      .l-toolbar__item span, .l-toolbar__item i {
        transition: color 0.2s;
        display: block;
        float: left;
        line-height: 24px;
        padding: 0 4px; }
      .l-toolbar__item i {
        margin-top: 0; }
      .l-toolbar__item img {
        float: left; }
      .l-toolbar__item:hover span, .l-toolbar__item:hover i, .l-toolbar__item:focus span, .l-toolbar__item:focus i {
        color: red; }
    .l-toolbar--left, .l-toolbar--right {
      height: 100%; } }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.bar-list {
  float: left;
  position: relative; }
  .bar-list__item {
    float: left;
    line-height: 69px;
    padding: 0 4px; }
    @media (max-width: 1024px) {
      .bar-list__item {
        line-height: 32px; } }
  .bar-list__item + .bar-list__item:before {
    display: block;
    content: "|";
    float: left;
    padding-right: 8px; }
    @media (max-width: 1024px) {
      .bar-list__item + .bar-list__item:before {
        display: none; } }
  .bar-list__link {
    line-height: 69px;
    padding: 0 4px;
    transition: color 0.2s;
    display: inline-block; }
    .bar-list__link:hover, .bar-list__link:focus {
      color: #e33232; }
  .bar-list__select {
    cursor: pointer;
    transition: color 0.2s; }
    .bar-list__select i {
      transition: color 0.2s; }
    .bar-list__select:hover, .bar-list__select:focus {
      color: #e33232; }
      .bar-list__select:hover i, .bar-list__select:focus i {
        color: #e33232; }
  .bar-list__dropdown {
    width: 150px;
    position: absolute;
    top: 55px;
    background-color: #fff;
    border: thin solid #ddd;
    padding: 4px 0;
    display: none;
    z-index: 4;
    margin-left: -110px; }
    .bar-list__dropdown li {
      line-height: 32px;
      padding: 0 8px;
      position: relative; }
      .bar-list__dropdown li a {
        display: block;
        width: 100%;
        line-height: 32px;
        color: #141414;
        transition: color 0.2s;
        text-align: right; }
      .bar-list__dropdown li:hover, .bar-list__dropdown li:focus {
        color: #e33232; }
        .bar-list__dropdown li:hover a, .bar-list__dropdown li:focus a {
          color: #e33232; }

.dropdown-wrapper {
  position: relative; }

.dropdown-mobile {
  display: none; }

@media (max-width: 1024px) {
  .dropdown-mobile {
    display: block;
    cursor: pointer;
    padding-left: 8px; }
    .dropdown-mobile i {
      transition: color 0.2s; }
    .dropdown-mobile:hover i, .dropdown-mobile:focus i {
      color: #e33232; }
  .bar-list__dropdown {
    top: 31px; }
  .bar-list--dropdown {
    width: 150px;
    position: absolute;
    top: 31px;
    background-color: #fff;
    border: thin solid #ddd;
    padding: 4px 0;
    display: none;
    z-index: 4;
    margin-left: -120px; }
    .bar-list--dropdown li {
      line-height: 32px;
      padding: 0 8px;
      position: relative;
      width: 100%; }
      .bar-list--dropdown li a {
        display: block;
        width: 100%;
        line-height: 32px;
        color: #141414;
        transition: color 0.2s; }
      .bar-list--dropdown li:hover, .bar-list--dropdown li:focus {
        color: #e33232; }
        .bar-list--dropdown li:hover a, .bar-list--dropdown li:focus a {
          color: #e33232; } }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.l-header {
  width: 100%;
  background-color: #fff;
  padding: 32px 0; }
  .l-header .viewport-wrapper {
    display: flex;
    justify-content: space-between; }
  .l-header__logo {
    float: left;
    margin-top: 12px;
    margin-right: 20px; }
    .l-header__logo--mobile {
      display: none; }
    .l-header__logo img {
      max-height: 58px; }
    @media (max-width: 1024px) {
      .l-header__logo {
        margin-top: 3px;
        margin-right: 8px;
        display: none; }
        .l-header__logo img {
          max-height: 25px;
          margin-top: 2px;
          margin-left: 3px; }
        .l-header__logo--mobile {
          display: block; } }
  .l-header__search {
    padding: 15px 0;
    margin-right: 10px;
    float: left; }
    @media (max-width: 1024px) {
      .l-header__search {
        padding: 0; } }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.news {
  color: #e33232; }
  .news:hover {
    color: #000 !important; }

.form {
  position: relative;
  display: block; }
  .form--wrapper {
    padding: 16px; }
  .form__field, .nonbox input[type="text"] {
    height: 40px;
    background-color: #fff;
    border: thin solid #ddd;
    padding-left: 8px;
    width: 190px;
    margin-right: -1px; }
    @media (max-width: 1240px) {
      .form__field, .nonbox input[type="text"] {
        width: 130px; } }
    @media (max-width: 375px) {
      .form__field, .nonbox input[type="text"] {
        width: 100px; } }
  .form__group {
    position: relative;
    width: 190px; }
    .form__group__radio {
      margin-top: 12px; }
  .form__field, .nonbox input[type="text"], .form__group, .form__helper {
    float: left; }
  .form__helper {
    line-height: 40px;
    color: #747474; }
  .form__submit {
    width: 44px;
    text-align: center;
    cursor: pointer;
    background-color: #e33232;
    border: 0;
    transition: background-color 0.2s;
    margin-right: 0; }
    .form__submit i {
      color: #fff; }
    .form__submit:hover, .form__submit:focus {
      background-color: #c50505; }
  .form__extend {
    float: left;
    display: block;
    padding: 10px 14px 9px; }
    @media (max-width: 1024px) {
      .form__extend {
        border: thin solid #ddd; } }
    .form__extend i {
      transition: color 0.2s; }
    .form__extend:hover i, .form__extend:focus i {
      color: #e33232; }

.select__arrow {
  position: absolute;
  background-color: #f5f5f5;
  display: block;
  width: 24px;
  height: 38px;
  margin-top: 1px;
  margin-right: 1px;
  right: 0; }

.mobile {
  display: none; }
  @media (max-width: 1024px) {
    .mobile {
      display: block;
      padding: 7px 2px;
      width: 25px;
      background: transparent; }
      .mobile i {
        color: #141414; }
      .mobile:hover, .mobile:focus {
        background-color: transparent; }
        .mobile:hover i, .mobile:focus i {
          color: #e33232; } }

.form--modern {
  padding: 8px 0; }
  .form--modern .form__field, .form--modern .nonbox input[type="text"], .nonbox .form--modern input[type="text"], .form--modern .form__group, .form--modern .form__helper {
    float: none; }
  .form--modern .form__row {
    padding: 8px 16px; }
  .form--modern .form__select {
    width: 250px;
    max-width: 100%; }
  .form--modern .button, .form--modern .bt, .form--modern .sbm2, .form--modern .sbm3, .form--modern .green_but, .form--modern .g_button {
    height: 40px;
    margin-left: 8px;
    cursor: pointer; }
  .form--modern .form__dates input {
    margin-top: 4px; }

@media (max-width: 1024px) {
  .form--inline {
    display: none;
    position: absolute;
    right: 16px;
    background: #fff;
    margin-top: -2px; } }

.form__group--line {
  margin-bottom: 16px; }
  .form__group--line .button, .form__group--line .bt, .form__group--line .sbm2, .form__group--line .sbm3, .form__group--line .green_but, .form__group--line .g_button {
    float: none !important; }
  .form__group--line textarea {
    height: 120px !important; }

.form__group__checkbox {
  padding-left: 16px; }

.form__group--hidden input, .form__group--hidden .label-group {
  display: none; }

.form__field--auto {
  float: none !important;
  width: auto !important; }

.form__link {
  display: block;
  padding-top: 8px; }

.form .example {
  display: block; }

@media (max-width: 640px) {
  .form__group__radio {
    padding-left: 16px; }
    .form__group__radio span {
      display: block;
      width: 100%; }
  .form__group--line .dp {
    width: 130px; } }

@media (max-width: 400px) {
  .form__group--line .dp {
    width: 90px; } }

.hidden-form {
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
  top: -9999px;
  left: -9999px; }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.l-menu {
  width: 100%;
  background-color: #e33232;
  height: 66px;
  z-index: 1; }

.stickyhead {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3); }

.free-space {
  min-height: 66px; }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.dropdown {
  position: relative; }
  .dropdown__list {
    height: 0;
    overflow: auto;
    max-height: 85vh;
    position: absolute;
    opacity: 0;
    transition: all 0.4s;
    min-width: 100%;
    margin-top: -32px;
    z-index: 1; }
    @media (max-width: 1240px) {
      .dropdown__list {
        height: auto;
        opacity: 1;
        margin-top: 0;
        max-height: 99999px; } }
  .dropdown__text {
    font-size: 1.2rem;
    display: block;
    line-height: 66px;
    height: 66px;
    padding: 0 18px;
    cursor: pointer;
    color: #fff;
    text-transform: uppercase;
    background-color: #e33232;
    transition: all 0.2s;
    position: relative;
    z-index: 2; }
    .dropdown__text i {
      color: #fff;
      transition: all 0.2s; }
  .dropdown__item {
    margin-top: -10px;
    opacity: 0.9; }
    .dropdown__item:nth-child(1) {
      transition: all 0.1s 0.05s ease-in; }
    .dropdown__item:nth-child(2) {
      transition: all 0.1s 0.1s ease-in; }
    .dropdown__item:nth-child(3) {
      transition: all 0.1s 0.15s ease-in; }
    .dropdown__item:nth-child(4) {
      transition: all 0.1s 0.2s ease-in; }
    .dropdown__item:nth-child(5) {
      transition: all 0.1s 0.25s ease-in; }
    .dropdown__item:nth-child(6) {
      transition: all 0.1s 0.3s ease-in; }
    .dropdown__item:nth-child(7) {
      transition: all 0.1s 0.35s ease-in; }
    .dropdown__item:nth-child(8) {
      transition: all 0.1s 0.4s ease-in; }
    .dropdown__item:nth-child(9) {
      transition: all 0.1s 0.45s ease-in; }
    .dropdown__item:nth-child(10) {
      transition: all 0.1s 0.5s ease-in; }
    .dropdown__item:nth-child(11) {
      transition: all 0.1s 0.55s ease-in; }
    .dropdown__item:nth-child(12) {
      transition: all 0.1s 0.6s ease-in; }
    .dropdown__item:nth-child(13) {
      transition: all 0.1s 0.65s ease-in; }
    .dropdown__item:nth-child(14) {
      transition: all 0.1s 0.7s ease-in; }
    .dropdown__item:nth-child(15) {
      transition: all 0.1s 0.75s ease-in; }
    .dropdown__item:nth-child(16) {
      transition: all 0.1s 0.8s ease-in; }
    .dropdown__item:nth-child(17) {
      transition: all 0.1s 0.85s ease-in; }
    .dropdown__item:nth-child(18) {
      transition: all 0.1s 0.9s ease-in; }
    .dropdown__item:nth-child(19) {
      transition: all 0.1s 0.95s ease-in; }
    .dropdown__item:nth-child(20) {
      transition: all 0.1s 1s ease-in; }
    .dropdown__item > a {
      padding: 5px 16px;
      display: block;
      width: 100%;
      background-color: #e33232;
      line-height: 1.4;
      transition: all 0.2s;
      color: #fff;
      white-space: nowrap; }
    .dropdown__item:hover > a, .dropdown__item:focus > a, .dropdown__item.is-open > a {
      background-color: #c50505; }
    @media (max-width: 1240px) {
      .dropdown__item {
        opacity: 1;
        margin-top: 0; }
        .dropdown__item > a {
          padding-left: 40px; } }

header {
  z-index: 3;
  position: relative; }

.nav {
  width: 100%; }
  .nav__list {
    width: 100%;
    height: 66px; }
  .nav__item {
    float: left;
    font-size: 1.25rem;
    color: #fff; }
    .nav__item > a {
      color: #fff;
      font-size: 1.25rem;
      line-height: 66px;
      display: block;
      padding: 0 8px;
      text-transform: uppercase;
      background-color: #e33232;
      transition: all 0.2s; }
    .nav__item:hover > a, .nav__item:hover > span, .nav__item:focus > a, .nav__item:focus > span, .nav__item.is-open > a, .nav__item.is-open > span {
      background-color: #c50505; }
    .nav__item:hover .dropdown__list, .nav__item:focus .dropdown__list, .nav__item.is-open .dropdown__list {
      display: block;
      opacity: 1;
      height: auto;
      margin-top: 0; }
      @media (max-width: 1240px) {
        .nav__item:hover .dropdown__list, .nav__item:focus .dropdown__list, .nav__item.is-open .dropdown__list {
          display: none; } }
    .nav__item:hover .dropdown__item, .nav__item:focus .dropdown__item, .nav__item.is-open .dropdown__item {
      margin-top: 0;
      opacity: 1; }
    @media (min-width: 1241px) {
      .nav__item:hover .dropdown__text i, .nav__item:focus .dropdown__text i, .nav__item.is-open .dropdown__text i {
        transform: rotate(180deg); } }

.toggle {
  float: left;
  background-color: #e33232;
  height: 44px;
  padding-top: 13px;
  cursor: pointer;
  display: none;
  z-index: 2;
  position: relative; }
  .toggle__bar {
    width: 32px;
    height: 3px;
    display: block;
    margin-bottom: 4px;
    background-color: #fff;
    margin-left: 2px; }

@media (max-width: 1240px) {
  .toggle {
    display: block;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%; }
  .nav {
    position: relative; }
    .nav__list {
      height: auto; }
    .nav__toggle {
      display: none;
      position: absolute;
      top: 0;
      margin-top: 0;
      padding-top: 44px;
      overflow: auto;
      position: absolute;
      z-index: 1;
      max-height: 80vh; }
    .nav__item {
      width: 100%;
      float: none; }
      .nav__item > a {
        font-size: 1rem;
        line-height: 44px;
        padding: 0 16px; }
      .nav__item:last-child .dropdown__list {
        right: 0; }
  .stickyhead .nav__toggle {
    max-height: 100vh; }
  .dropdown {
    min-height: 44px; }
    .dropdown__list {
      position: relative; }
    .dropdown__text {
      font-size: 1rem;
      line-height: 44px;
      max-height: 44px;
      padding: 0 16px; }
  .free-space {
    min-height: 44px; }
  .l-menu {
    height: 44px; }
    .l-menu .viewport-wrapper {
      padding: 0; } }

.nav__item:last-child .dropdown__list {
  right: 0; }

@media (max-width: 1240px) {
  .dropdown__list {
    display: none; }
  .list-show span i {
    transform: rotate(180deg); } }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.news-bg {
  background-color: #fff;
  width: 100%; }

.hot-news {
  padding: 16px 0;
  display: flex;
  background-color: #fff; }
  @media (max-width: 1024px) {
    .hot-news {
      display: block; } }
  .hot-news__title {
    font-size: 100%;
    min-width: 160px; }
    @media (max-width: 1024px) {
      .hot-news__title {
        float: left;
        min-width: 0; } }
    @media (max-width: 620px) {
      .hot-news__title {
        display: none; } }
  .hot-news__items {
    padding-left: 8px; }
    @media (max-width: 620px) {
      .hot-news__items {
        padding-left: 0;
        white-space: nowrap;
        overflow: auto; } }
  .hot-news__item {
    font-weight: 300;
    color: #747474;
    transition: color 0.2s;
    padding: 0 8px;
    white-space: nowrap;
    line-height: 1.5rem; }
    .hot-news__item:hover, .hot-news__item:focus {
      color: #141414; }
    @media (max-width: 1024px) {
      .hot-news__item {
        text-transform: none;
        font-size: 0.875rem;
        white-space: nowrap; } }
    @media (max-width: 620px) {
      .hot-news__item {
        padding-bottom: 12px;
        white-space: wrap;
        display: inline-block; } }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.l-site {
  max-width: 1600px;
  display: flex;
  padding: 16px;
  margin: 0 auto; }
  @media (max-width: 1024px) {
    .l-site {
      flex-wrap: wrap; } }
  .l-site--left {
    flex: 0 0 19.2%;
    max-width: 19.2%;
    margin-right: 1%; }
    @media (max-width: 1024px) {
      .l-site--left {
        order: 2;
        flex: 0 0 39%;
        max-width: 39%; } }
  .l-site--center {
    flex: 0 0 54.3%;
    margin-left: 1%;
    margin-right: 1%;
    height: fit-content;
    max-width: 54.3%; }
    @media (max-width: 1024px) {
      .l-site--center {
        order: 1;
        width: 100%;
        flex: 0 0 100%;
        margin: 0 0 16px 0;
        max-width: 100%; } }
    @media (max-width: 620px) {
      .l-site--center {
        margin-top: 8px; } }
  .l-site--right {
    flex: 0 0 22.5%;
    margin-left: 1%; }
    @media (max-width: 1024px) {
      .l-site--right {
        order: 3;
        flex: 0 0 60%;
        max-width: 60%;
        margin-left: 0; } }

@media (max-width: 620px) {
  .l-site {
    padding: 4px; }
    .l-site--left {
      flex: 0 0 100%;
      max-width: 100%;
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 0;
      padding-right: 0; }
    .l-site--center {
      flex: 0 0 100%;
      margin-left: 0;
      margin-right: 0; }
    .l-site--right {
      flex: 0 0 100%;
      max-width: 100%;
      margin-left: 0;
      margin-bottom: 16px; }
  .viewport-wrapper {
    padding: 0 4px; } }

.social-links a {
  display: inline-block;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 44px; }
  .social-links a i {
    color: #fff; }
  .social-links a:hover i, .social-links a:focus i {
    color: #e33232; }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.l-site--center, .card {
  width: 100%;
  background-color: #fff;
  border: thin solid #ddd;
  min-height: 10px;
  box-shadow: 0 0 0 0 rgba(0, 1, 2, 0.1); }

.card {
  padding: 16px;
  margin-bottom: 16px; }
  .card__title {
    font-size: 1.25rem;
    color: #141414;
    position: relative;
    margin-bottom: 16px;
    padding-bottom: 16px;
    text-transform: uppercase; }
    .card__title > a {
      font-size: 1.25rem;
      color: #e33232; }
    .card__title:after {
      width: 80px;
      height: 3px;
      background-color: #e33232;
      position: absolute;
      content: " ";
      bottom: 8px;
      left: 0; }
  .card__image {
    position: relative;
    margin-bottom: 16px; }
    .card__image img {
      width: 100%; }
    .card__image__title {
      padding: 16px;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.7);
      width: 100%;
      overflow: hidden;
      position: absolute;
      bottom: 0;
      left: 0;
      opacity: 0.5;
      transition: all 0.2s;
      margin-bottom: 4px;
      max-height: 100%; }
      .card__image__title a {
        color: #e33232;
        font-size: 1.25rem; }
      @media (max-width: 1024px) {
        .card__image__title {
          opacity: 1; } }
    .card__image:hover .card__image__title, .card__image:focus .card__image__title {
      opacity: 1; }
    .card__image:hover #pic_text .card__image__title, .card__image:focus #pic_text .card__image__title {
      display: block;
      opacity: .8; }
  .card__list {
    margin-bottom: 16px; }
    .card__list__item {
      padding-bottom: 4px; }
    .card__list__link {
      transition: color 0.2s; }
      .card__list__link:hover, .card__list__link:focus {
        color: #e33232; }
    .card__list__type {
      font-size: 0.75rem;
      color: #747474; }
    .card__list__rating {
      color: #747474;
      font-size: 0.75rem; }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.post__footer {
  position: relative;
  padding-top: 120px; }
  .post__footer .gallery_wrapper {
    position: absolute;
    width: 100%;
    margin-top: -120px; }
  .post__footer--no-padding {
    padding-top: 0; }

.post__title, .post__description, .post p {
  padding: 16px;
  max-width: 720px;
  margin: 0 auto; }

.post__description--no-padding {
  padding-top: 16px !important; }
  .post__description--no-padding > * {
    padding: 0 !important; }
  .post__description--no-padding > h2 {
    padding: 0 0 16px 0 !important; }

.post__description, .post p {
  padding-top: 0;
  color: #141414;
  font-size: 1rem;
  line-height: 150%; }

.post__description__link, .post__description__link i {
  color: #141414;
  transition: color 0.2s; }
  .post__description__link:hover, .post__description__link:focus, .post__description__link:hover i, .post__description__link:focus i, .post__description__link i:hover, .post__description__link i:focus, .post__description__link i:hover i, .post__description__link i:focus i {
    color: #e33232; }

.post__title {
  color: #141414;
  transition: color 0.2s;
  font-size: 1.5rem; }
  .post__title a {
    font-size: 1.5rem; }
  .post__title:hover, .post__title:focus {
    color: #e33232; }
  .post__title--hover-off:hover, .post__title--hover-off:focus {
    color: #141414; }

.post__service {
  padding: 16px;
  clear: both; }
  @media (max-width: 1024px) {
    .post__service {
      text-align: center; } }
  .post__service a {
    transition: color 0.2s; }
    .post__service a:hover, .post__service a:focus {
      color: #e33232; }

.post__info {
  text-align: right;
  color: #747474;
  font-size: 0.75rem !important;
  padding: 0 16px 16px 16px;
  max-width: 720px;
  margin: 0 auto; }
  .post__info--padding {
    padding: 16px; }
  .post__info__link {
    text-decoration: underline;
    color: #141414;
    transition: color 0.2s;
    float: none;
    font-size: 0.75rem; }
    .post__info__link:hover, .post__info__link:focus {
      color: #e33232; }
    .post__info__link--big {
      font-size: 0.875rem; }

.post--small .post__image {
  float: left;
  margin: 16px; }

.post--small > a {
  float: left; }

.l-posts {
  padding-bottom: 16px; }
  .l-posts__wrapper {
    padding: 0 16px; }
  .l-posts__title {
    text-transform: uppercase;
    font-size: 1.5rem;
    color: #141414;
    padding: 16px;
    position: relative;
    margin-bottom: 8px; }
    @media (max-width: 620px) {
      .l-posts__title {
        font-size: 1.25rem; } }
    .l-posts__title:after {
      width: 80px;
      height: 3px;
      background-color: #e33232;
      position: absolute;
      content: " ";
      left: 16px;
      bottom: 8px; }

.separator {
  width: 100%;
  height: 1px;
  display: block;
  background-color: #ddd;
  clear: both; }

@media (min-width: 621px) {
  .js-middle-300 {
    width: 300px;
    max-width: 50%;
    text-align: center;
    display: inline-block;
    padding: 16px 0 16px 16px; }
    .js-middle-300 > a {
      float: none; }
  .post--small > .js-middle-300 {
    max-width: 300px; }
  .post--small .post__wrapper {
    flex: 1; }
  .post--small .post__image {
    width: 298px;
    margin: 0; }
  .post--small .post__info, .post--small .l-posts__wrapper {
    width: 100%; } }

@media (max-width: 620px) {
  .post {
    font-size: 0.75rem; }
    .post__title {
      font-size: 1.1rem !important; }
    .post > a {
      text-decoration: underline; }
    .post__description, .post p, .post a {
      font-size: 0.875rem; } }

@media (max-width: 480px) {
  .post--small .post__image {
    margin: 16px auto 0 auto;
    max-width: 100%;
    float: none; }
  .post--small > a {
    display: block;
    width: 100%;
    text-align: center;
    float: none; } }

.post .bxp {
  width: 100%;
  display: block; }
  .post .bxp img {
    width: 100%;
    height: auto; }

.post .author .bxp img {
  width: 48px;
  height: 48px; }

.post--small .bxp {
  width: auto;
  display: inline-block; }
  .post--small .bxp img {
    width: auto; }

.post--solo .post__info {
  text-align: left; }

.post--solo.post--small {
  display: block; }
  .post--solo.post--small .post__description, .post--solo.post--small .post__title, .post--solo.post--small .post p {
    max-width: 100%; }
  .post--solo.post--small .post__footer {
    max-width: 720px;
    margin: 0 auto;
    clear: both; }
  .post--solo.post--small .bxp {
    padding-right: 16px; }

.post__brand {
  margin-bottom: 16px; }
  @media (max-width: 620px) {
    .post__brand {
      flex-wrap: wrap; } }
  .post__brand__logo {
    padding: 16px; }
  .post__brand__info {
    margin-top: 16px; }

.span-wrapper .span-line {
  display: block;
  line-height: 24px; }

.post__image__full {
  width: 100%;
  height: auto;
  margin-top: 16px; }

.post__image--small {
  margin: 16px !important; }

.post--small .post__title, .post--small .post__description, .post--small .post__info, .post--small p {
  max-width: 100% !important; }

.post--full {
  width: 100%;
  position: relative; }

.post__image--main {
  width: 100%;
  height: auto;
  margin-top: 16px; }

.l-posts__wrapper {
  padding: 16px; }

.post__wrapper--full {
  width: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.65);
  color: #fff;
  bottom: 0;
  margin-bottom: 4px; }
  .post__wrapper--full .post__title, .post__wrapper--full .post__description, .post__wrapper--full .post__info, .post__wrapper--full .post__info__link {
    color: #fff;
    text-align: left;
    max-width: 100%; }
  .post__wrapper--full .post__title {
    padding-bottom: 8px;
    transition: color 0.2s; }
    .post__wrapper--full .post__title:hover, .post__wrapper--full .post__title:focus {
      color: #e33232; }
  .post__wrapper--full .post__info {
    text-align: right;
    padding-top: 8px; }
  .post__wrapper--full .post__info__link {
    text-align: right;
    padding: 0 2px;
    transition: color 0.2s; }
    .post__wrapper--full .post__info__link a {
      color: #fff; }
  .post__wrapper--full .post__description {
    padding-bottom: 0; }
    .post__wrapper--full .post__description a {
      color: #fff;
      transition: color 0.2s; }
      .post__wrapper--full .post__description a:hover, .post__wrapper--full .post__description a:focus {
        color: #e33232; }

.post--solo .post__info {
  margin: 0; }

.post--brand .card__title {
  padding: 40px 16px 16px 16px; }
  .post--brand .card__title:after {
    margin-left: 16px; }

.post__title--notice {
  font-weight: 400;
  color: #747474;
  font-size: 1rem;
  padding: 0 16px;
  display: block; }
  .post__title--notice:hover, .post__title--notice:focus {
    color: #747474; }
  @media (max-width: 620px) {
    .post__title--notice {
      font-size: 0.85rem; } }

.post__title--bold {
  font-weight: 700;
  font-size: 1.1rem; }

.closed {
  color: #e33232;
  display: block; }

.post__title--full, .post__description--full, .post__info--full {
  max-width: 100%; }

.post .post__title--full, .post .post__description--full, .post .post__info--full {
  max-width: 100%; }

.post .description__title {
  padding: 0;
  font-size: 1.25rem;
  color: #141414;
  font-weight: 700;
  padding-bottom: 16px; }

.post .article_text, .post .object-description u, .object-description .post u {
  color: #141414;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0; }
  .post .article_text i, .post .object-description u i, .object-description .post u i {
    line-height: normal;
    color: #141414;
    display: inline-block; }
  @media (max-width: 620px) {
    .post .article_text, .post .object-description u, .object-description .post u {
      font-size: 0.875rem; } }

.bottom-offset {
  margin-bottom: 8px; }

.page__description ul {
  padding-left: 24px; }

a.post__title {
  display: inline-block; }

.post-news > div {
  max-width: 720px;
  margin: 0 auto; }

.object-description .full__description u, .object-description .full__description .post__description.page__description {
  max-width: 720px;
  margin: 0 auto; }

.post .post__details {
  margin-top: -16px;
  font-size: 0.875rem;
  color: #747474; }

.post p {
  padding-top: 8px !important;
  padding-bottom: 8px !important; }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

footer {
  padding: 32px 0;
  background-color: #141414; }

.footer__list {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  .footer__list li a {
    color: #fff;
    transition: color 0.2s; }
    @media (max-width: 620px) {
      .footer__list li a {
        padding: 4px; } }
    .footer__list li a:hover, .footer__list li a:focus {
      color: #e33232; }
    @media (max-width: 620px) {
      .footer__list li a {
        font-size: 0.875rem; } }
  .footer__list li + li:before {
    display: inline-block;
    content: "|";
    color: #fff;
    padding: 0 16px; }
    @media (max-width: 620px) {
      .footer__list li + li:before {
        display: none; } }

.footer__info {
  max-width: 600px;
  width: 100%;
  margin: 16px auto;
  color: #747474;
  font-size: 0.875rem;
  text-align: center; }
  @media (max-width: 620px) {
    .footer__info {
      font-size: 0.75rem; } }

.status1 {
  background: url(../images/status.png) 0 0 no-repeat; }

.status3 {
  background: url(../images/status.png) 0 -37px no-repeat; }

.status4 {
  background: url(../images/status.png) 0 -56px no-repeat; }

.status2 {
  background: url(../images/status.png) 0 -19px no-repeat; }

.status {
  width: 12px;
  height: 12px;
  display: inline-block;
  margin: 0 4px; }

.card__list__item .status1:hover, .card__list__item .status1:focus {
  background: url(../images/status.png) -13px 0 no-repeat; }

.card__list__item .status3:hover, .card__list__item .status3:focus {
  background: url(../images/status.png) -13px -37px no-repeat; }

.card__list__item .status4:hover, .card__list__item .status4:focus {
  background: url(../images/status.png) -13px -56px no-repeat; }

.card__list__item .status2:hover, .card__list__item .status2:focus {
  background: url(../images/status.png) -13px -19px no-repeat; }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.button, .bt, .sbm2, .sbm3, .green_but, .g_button {
  min-width: 100px;
  padding: 8px 16px;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 300;
  text-align: center;
  background-color: #e33232;
  transition: background-color 0.2s;
  display: inline-block;
  cursor: pointer;
  position: relative; }
  .button i, .bt i, .sbm2 i, .sbm3 i, .green_but i, .g_button i {
    color: #fff; }
  .button:hover, .bt:hover, .sbm2:hover, .sbm3:hover, .green_but:hover, .g_button:hover, .button:focus, .bt:focus, .sbm2:focus, .sbm3:focus, .green_but:focus, .g_button:focus {
    background-color: #c50505;
    box-shadow: 0 1px 8px -2px #e33232; }
  .button:active, .bt:active, .sbm2:active, .sbm3:active, .green_but:active, .g_button:active {
    top: 2px;
    box-shadow: 0 3px 10px -2px #e33232; }
  .button--solo {
    margin-left: 16px;
    margin-top: 12px;
    margin-bottom: 16px; }
  .button--right {
    float: right;
    margin-right: 16px;
    margin-top: 0; }
  .button--orange, .sbm3, .red_but {
    padding: 8px 16px;
    position: relative;
    text-decoration: none;
    display: block;
    background-color: #1ba6de;
    transition: background-color 0.2s; }
    .button--orange:hover, .sbm3:hover, .red_but:hover, .button--orange:focus, .sbm3:focus, .red_but:focus {
      color: #fff;
      background-color: #4dc9fb;
      box-shadow: 0 1px 8px -2px #1ba6de; }
    .button--orange:active, .sbm3:active, .red_but:active {
      top: 2px;
      box-shadow: 0 3px 10px -2px #1ba6de; }
  .button--green, .g_button {
    background-color: #5fb42c;
    position: relative; }
    .button--green:hover, .g_button:hover, .button--green:focus, .g_button:focus {
      background-color: #1e9931;
      box-shadow: 0 1px 8px -2px #5fb42c; }
    .button--green:active, .g_button:active {
      top: 2px;
      box-shadow: 0 3px 10px -2px #5fb42c; }
  .button--more {
    margin-left: 16px;
    margin-top: 16px;
    text-decoration: none; }

.form__link .button--orange, .form__link .sbm3, .form__link .red_but {
  padding: 8px 16px;
  margin-top: 16px;
  text-decoration: none;
  display: block;
  background-color: #1ba6de;
  transition: background-color 0.2s;
  position: relative; }
  .form__link .button--orange:hover, .form__link .sbm3:hover, .form__link .red_but:hover, .form__link .button--orange:focus, .form__link .sbm3:focus, .form__link .red_but:focus {
    color: #fff;
    background-color: #4dc9fb;
    box-shadow: 0 1px 8px -2px #1ba6de; }
  .form__link .button--orange:active, .form__link .sbm3:active, .form__link .red_but:active {
    top: 2px;
    box-shadow: 0 3px 10px -2px #1ba6de; }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.card .separator {
  margin-bottom: 16px; }

.review {
  margin-bottom: 16px; }
  .review__title {
    font-size: 1.25rem;
    padding-top: 12px;
    font-weight: 600; }
    @media (max-width: 620px) {
      .review__title {
        font-size: 1rem; } }
  .review__subject {
    padding-top: 16px;
    display: block;
    font-weight: 600; }
  .review__text {
    color: #747474;
    padding: 8px 0;
    word-break: break-word; }
    @media (max-width: 620px) {
      .review__text {
        font-size: 0.875rem; } }
    .review__text__more {
      transition: color 0.2s; }
      .review__text__more:hover, .review__text__more:focus {
        color: #e33232; }
        .review__text__more:hover i, .review__text__more:focus i {
          color: #e33232; }
  .review__footer__link {
    text-decoration: underline;
    transition: color 0.2s; }
    .review__footer__link:hover, .review__footer__link:focus {
      color: #e33232; }
    @media (max-width: 620px) {
      .review__footer__link {
        font-size: 0.875rem; } }
  .review__footer__type {
    color: #747474;
    font-size: 0.75rem; }
    .review__footer__type--last {
      display: block;
      clear: both; }

.author {
  display: flex; }
  .author__photo {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    flex: 0 0 48px; }
    .author__photo img {
      width: 48px;
      height: 48px; }
  .author__info {
    flex: 1;
    padding-left: 16px;
    display: flex;
    justify-content: center;
    flex-direction: column; }
    .author__info__name {
      font-weight: 600; }
    .author__info__date {
      color: #747474;
      font-size: 0.75rem; }

.review--horizantal {
  padding: 16px; }

.review__footer--flex {
  display: flex;
  justify-content: space-between; }

.author__add__status {
  font-size: 0.75rem;
  font-weight: 600; }

.author__add__count {
  font-size: 0.75rem; }

.author_add__status {
  font-size: 0.75rem;
  font-weight: 600; }

.mark_pos {
  font-weight: 700;
  color: #19d465; }

.mark_neg {
  font-weight: 700;
  color: #dc0c0c; }

.socialnet {
  display: block !important; }

.rest_div {
  display: none; }

.review-flex {
  display: flex;
  justify-content: space-between; }

.social__item {
  cursor: pointer;
  padding: 2px 8px;
  border: thin solid #ddd; }
  .social__item .fa-facebook-official {
    color: #3b5998; }
  .social__item .fa-twitter {
    color: #00aced; }
  .social__item .fa-google-plus {
    color: #db3236; }
  .social__item:hover i, .social__item:focus i {
    color: #e33232; }
  .social__item span {
    display: inline-block;
    padding-left: 4px;
    font-size: 0.75rem;
    color: #747474; }

.thumb_up:before {
  content: "\f087";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit; }
  .thumb_up:before:hover, .thumb_up:before:focus {
    color: #19d465; }

.thumb_down:before {
  content: "\f088";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit; }

.thumb_down:hover, .thumb_down:focus {
  color: #dc0c0c; }

.thumb_up:hover, .thumb_up:focus {
  color: #19d465; }

.review__footer__right {
  font-size: 0.875rem; }
  .review__footer__right a, .review__footer__right span {
    font-size: 0.875rem; }

.post .place .bxp .post__image__small {
  width: 16px;
  margin: 6px 6px 4px 0; }

.minus2, .plus2 {
  display: block;
  width: 16px;
  height: 16px;
  position: relative;
  float: left; }
  .minus2:before, .plus2:before {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: thin solid #747474;
    display: block;
    position: asbolute;
    line-height: 16px;
    text-align: center;
    color: #747474;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    font-size: 10px; }

.rest {
  clear: both; }

.rest_name span {
  float: left;
  margin-right: 12px;
  float: left;
  display: block; }

.rest_name .image-as-icon {
  margin-right: 8px;
  width: 24px; }

.minus2:before {
  content: "\f068"; }

.plus2:before {
  content: "\f067"; }

span.more {
  color: #141414; }

.review--level {
  padding-left: 10%;
  margin-top: -32px; }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.check {
  display: block;
  position: absolute;
  border: 3px solid #747474;
  border-radius: 100%;
  height: 21px;
  width: 21px;
  transition: border .15s linear;
  top: 10px; }
  .check .rating__item:hover .check {
    border: 3px solid #ccc; }

.check::before {
  display: block;
  position: absolute;
  content: '';
  border-radius: 100%;
  height: 7px;
  width: 7px;
  top: 4px;
  left: 4px;
  margin: auto;
  transition: background 0.15s linear; }

input[type=radio]:checked ~ .check {
  border: 3px solid #e33232; }

input[type=radio]:checked ~ .check:before {
  background: #e33232;
  transition: background 0.15s linear; }

input[type=radio]:checked ~ label {
  color: #e33232;
  transition: color 0.15s linear; }

.tap-effect {
  height: 30px;
  width: 30px;
  margin-left: -2px;
  margin-top: -2px;
  opacity: 0;
  position: absolute;
  background-color: #e33232;
  border-radius: 50%; }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.rating {
  position: relative; }
  .rating__item {
    position: relative;
    padding: 12px 0 8px 0; }
    .rating__item__label {
      display: block;
      position: relative;
      cursor: pointer;
      transition: all 0.25s linear;
      padding-left: 32px;
      z-index: 1;
      font-size: 0.9em; }
    .rating__item__input {
      position: absolute;
      visibility: hidden; }
    .rating__item:hover label {
      color: #e33232; }
  .rating__info {
    color: #747474;
    font-size: 0.875rem; }
  .rating__value__info {
    color: #747474;
    font-size: 0.75rem;
    padding-top: 2px;
    padding-bottom: 2px; }
  .rating__value__bar {
    position: relative;
    margin: 8px 0; }
    .rating__value__bar .full, .rating__value__bar .filled {
      display: block;
      position: absolute;
      height: 2px;
      top: 0;
      left: 0; }
    .rating__value__bar .full {
      width: 100%;
      background-color: #ddd; }

.rating__item:nth-child(1) .rating__value__bar .filled {
  background-color: #4286f4; }

.rating__item .check {
  display: none !important; }

.rating__item:nth-child(2) .rating__value__bar .filled {
  background-color: #b028d6; }

.rating__item .check {
  display: none !important; }

.rating__item:nth-child(3) .rating__value__bar .filled {
  background-color: #b22a31; }

.rating__item .check {
  display: none !important; }

.rating__item:nth-child(4) .rating__value__bar .filled {
  background-color: #28ba39; }

.rating__item .check {
  display: none !important; }

.rating__item:nth-child(5) .rating__value__bar .filled {
  background-color: #f4f00e; }

.rating__item .check {
  display: none !important; }

.rating__item:nth-child(6) .rating__value__bar .filled {
  background-color: #f28715; }

.rating__item .check {
  display: none !important; }

.rating__item:nth-child(7) .rating__value__bar .filled {
  background-color: #4ed9e0; }

.rating__item .check {
  display: none !important; }

.rating__item:nth-child(8) .rating__value__bar .filled {
  background-color: #0a0a66; }

.rating__item .check {
  display: none !important; }

.rating__item:nth-child(9) .rating__value__bar .filled {
  background-color: #147246; }

.rating__item .check {
  display: none !important; }

.rating__item:nth-child(10) .rating__value__bar .filled {
  background-color: #97c9b1; }

.rating__item .check {
  display: none !important; }

.rating__item:nth-child(11) .rating__value__bar .filled {
  background-color: #ce000a; }

.rating__item .check {
  display: none !important; }

.rating__item:nth-child(12) .rating__value__bar .filled {
  background-color: #e5b650; }

.rating__item .check {
  display: none !important; }

.sbm1 {
  margin-top: 16px;
  cursor: pointer; }

span.rating__item__label {
  padding-left: 0; }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex; }

.hidden {
  display: none;
  visibility: hidden; }

.invisible {
  visibility: hidden; }

/*Slick Slider*/
.slick-slider {
  visibility: hidden; }
  .slick-slider.slick-initialized {
    visibility: visible; }

.opts .radio-group {
  margin-bottom: 4px; }

.reg-page .frm {
  margin-top: 20px;
  margin-bottom: 15px; }

.form__group__checkbox input[type='checkbox'],
#dialog_popup input[type='checkbox'],
.sort-control input[type='checkbox'],
.remember input[type='checkbox'],
.show-pas input[type='checkbox'],
.register-group.checkbox input[type='checkbox'],
.reg-page .subscribe-on input[type='checkbox'] {
  display: none !important;
  visibility: hidden !important; }
  .form__group__checkbox input[type='checkbox']:checked + label:before,
  #dialog_popup input[type='checkbox']:checked + label:before,
  .sort-control input[type='checkbox']:checked + label:before,
  .remember input[type='checkbox']:checked + label:before,
  .show-pas input[type='checkbox']:checked + label:before,
  .register-group.checkbox input[type='checkbox']:checked + label:before,
  .reg-page .subscribe-on input[type='checkbox']:checked + label:before {
    content: '\f00c';
    font-family: FontAwesome;
    border-color: #999;
    color: #e33232;
    font-size: 18px;
    text-align: center;
    line-height: 1.2; }

.form__group__checkbox label,
#dialog_popup label,
.sort-control label,
.remember label,
.show-pas label,
.register-group.checkbox label,
.reg-page .subscribe-on label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 300; }
  .form__group__checkbox label ~ br,
  #dialog_popup label ~ br,
  .sort-control label ~ br,
  .remember label ~ br,
  .show-pas label ~ br,
  .register-group.checkbox label ~ br,
  .reg-page .subscribe-on label ~ br {
    display: none !important; }
  .form__group__checkbox label a,
  #dialog_popup label a,
  .sort-control label a,
  .remember label a,
  .show-pas label a,
  .register-group.checkbox label a,
  .reg-page .subscribe-on label a {
    color: #e33232;
    text-decoration: underline; }
    .form__group__checkbox label a:hover,
    #dialog_popup label a:hover,
    .sort-control label a:hover,
    .remember label a:hover,
    .show-pas label a:hover,
    .register-group.checkbox label a:hover,
    .reg-page .subscribe-on label a:hover {
      text-decoration: none; }
  .form__group__checkbox label:before,
  #dialog_popup label:before,
  .sort-control label:before,
  .remember label:before,
  .show-pas label:before,
  .register-group.checkbox label:before,
  .reg-page .subscribe-on label:before {
    content: '';
    width: 21px;
    height: 21px;
    display: inline-block;
    vertical-align: -6px;
    background-size: 13px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
    margin-right: 10px; }

input[type="radio"] {
  position: absolute;
  left: -9999px; }
  input[type="radio"]:checked + label, input[type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 24px;
    display: inline-block;
    cursor: pointer; }
    input[type="radio"]:checked + label:before, input[type="radio"]:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 17px;
      height: 17px;
      border: 2px solid #ccc;
      border-radius: 100%;
      background: #fff; }
    input[type="radio"]:checked + label:after, input[type="radio"]:not(:checked) + label:after {
      content: '';
      width: 11px;
      height: 11px;
      background: #e33232;
      position: absolute;
      top: 5px;
      left: 5px;
      border-radius: 100%;
      transition: all 0.2s ease; }
  input[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0); }
  input[type="radio"]:checked + label:after {
    opacity: 1;
    transform: scale(1); }
  input[type="radio"]:checked + label:before {
    border-color: #e33232; }

.pages_count {
  padding: 5px 15px 5px 10px; }
  @media (max-width: 480px) {
    .pages_count {
      padding: 4px 8px;
      font-size: 0.85rem; } }

.hidden-items li:nth-child(2), .hidden-items li:nth-child(3), .hidden-items li:nth-child(12), .hidden-items li:nth-child(15) {
  display: none; }

.lnk-block li a {
  transition: color 0.2s; }
  .lnk-block li a:hover, .lnk-block li a:focus {
    color: #e33232; }

.l_men {
  display: flex;
  justify-content: space-around;
  text-align: center; }
  .l_men__item:hover a, .l_men__item:focus a {
    transition: color 0.2s;
    color: #e33232; }

.alone-link {
  display: block;
  padding: 8px 16px;
  text-decoration: underline; }
  .alone-link:hover, .alone-link:focus {
    color: #e33232;
    transition: color 0.2s; }

.card__video {
  display: block;
  position: relative;
  text-align: center; }

.play-icon {
  opacity: 0.7;
  font-size: 2rem !important;
  transition: opacity 0.2s;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -16px;
  margin-top: -16px; }

.video__image {
  max-width: 100%;
  margin: 0 auto; }

.video__title {
  color: #141414;
  margin: 8px 0;
  transition: color 0.2s;
  display: block; }
  .video__title:hover, .video__title:focus {
    color: #e33232; }
    .video__title:hover .play-icon, .video__title:focus .play-icon {
      opacity: 0.9; }

.img_bounds {
  margin: 0 !important;
  clear: both;
  padding: 16px; }
  .img_bounds .post__image__small {
    float: left;
    margin-right: 16px;
    margin-bottom: 20px; }
    @media (max-width: 550px) {
      .img_bounds .post__image__small {
        width: 100%;
        max-width: 550px;
        float: none; } }
  .img_bounds .def-link img {
    width: 100%;
    max-width: initial;
    float: none; }

.left {
  float: left; }

.image__label, .bxp {
  position: relative; }
  .image__label .comment, .bxp .comment {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 16px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    margin-bottom: 4px;
    font-weight: 400;
    font-size: 1.25rem; }
    .image__label .comment i, .bxp .comment i {
      color: #fff; }

.tab_ob {
  display: block !important; }

input::-webkit-input-placeholder {
  color: #747474; }

input::-moz-placeholder {
  color: #747474; }

input:-ms-input-placeholder {
  color: #747474; }

input:-moz-placeholder {
  color: #747474; }

#cboxOverlay {
  background: #000 !important; }

#cboxBottomLeft, #cboxBottomCenter, #cboxBottomRight, #cboxTopLeft, #cboxTopCenter, #cboxTopRight, #cboxMiddleLeft, #cboxMiddleRight {
  display: none; }

#cboxWrapper {
  max-height: 100vh;
  overflow-y: hidden;
  max-width: 100% !important; }

#colorbox {
  max-width: 320px !important;
  right: 0 !important;
  left: 0 !important;
  margin: auto !important; }
  @media (max-width: 480px) {
    #colorbox {
      max-width: 320px !important; } }

#choose_city ul {
  padding-left: 35px;
  padding-top: 20px; }

#choose_city li {
  background-repeat: no-repeat;
  padding: 2px;
  padding-left: 32px;
  padding-bottom: 5px; }

#dialog_popup {
  position: relative;
  padding-left: 12px; }

#cboxClose {
  background: none !important;
  text-indent: 0 !important;
  font-size: 0;
  width: 50px;
  height: 30px;
  right: 20px; }

#cboxClose:before {
  content: '\f00d';
  font-family: FontAwesome;
  position: absolute;
  width: 25px;
  height: 25px;
  font-size: 25px;
  margin-top: -5px; }

#cboxWrapper, #colorbox {
  background: #fff !important;
  border: 0 !important;
  outline: 0 !important; }

.clear {
  clear: both; }

#cboxContent {
  max-height: 100vh !important;
  overflow: auto;
  width: 100% !important;
  height: 100% !important; }

#cboxLoadedContent {
  width: auto !important;
  max-width: 420px;
  margin-right: 0 !important;
  overflow: visible !important;
  margin-top: 0 !important;
  margin-bottom: 15px !important; }

#login {
  height: auto !important;
  margin-top: 40px;
  padding: 20px;
  width: 100% !important; }
  #login .login__button {
    margin-bottom: 15px; }
  #login .message__error {
    margin-bottom: 8px; }

.form__link {
  padding: 0 !important; }
  .form__link a {
    font-size: 14px;
    text-decoration: underline;
    padding: 4px 0;
    display: block; }
  .form__link .button, .form__link .bt, .form__link .sbm2, .form__link .sbm3, .form__link .green_but, .form__link .g_button {
    margin-top: 25px;
    font-weight: 500;
    font-size: 15px;
    padding: 11px 16px; }

.login__button .button, .login__button .bt, .login__button .sbm2, .login__button .sbm3, .login__button .green_but, .login__button .g_button {
  font-weight: 500;
  font-size: 15px;
  margin: 0 auto;
  display: block;
  cursor: pointer; }

#soclist {
  display: flex;
  align-items: center; }
  #soclist li {
    font-size: 14px;
    padding: 0 !important; }
    #soclist li a {
      padding: 4px;
      display: inline-block;
      min-height: 12px;
      min-width: 12px; }
      #soclist li a i {
        font-size: 26px;
        padding-left: 4px; }

.socialnet {
  padding-top: 16px;
  margin-bottom: 16px;
  height: 40px; }

.li-title {
  padding-top: 3px; }

.hidden-all {
  display: none; }

footer {
  position: relative;
  z-index: 1; }

.addthis-items {
  padding-top: 16px; }

@media (max-width: 480px) {
  .post--full .post__description {
    display: none; }
  .post--full .post__info {
    text-align: left;
    padding-top: 0; } }

@media (max-width: 620px) {
  .post__image--small {
    margin: 0 auto !important;
    float: none !important; }
  .post--small > a {
    float: none;
    width: 100%;
    display: block;
    text-align: center; } }

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset; }

.post--solo > div {
  padding-left: 16px;
  padding-right: 16px; }

.post--solo .card__image {
  width: auto;
  float: left;
  margin: 16px; }

.post--solo .post__info {
  max-width: 100%; }

.post--solo .post__title, .post--solo .post__description {
  max-width: 100%; }

.post--solo .bxp .card__image {
  width: auto; }
  @media (max-width: 670px) {
    .post--solo .bxp .card__image {
      display: block;
      float: none;
      margin: 0 auto; } }

.more__title, .more-link-list {
  padding-left: 16px;
  padding-right: 16px; }

.post--solo > .image__label, .post--solo > .post__footer {
  padding-right: 0;
  padding-left: 0; }

.post .post__title--limited, .post .post__text--limited, .post .post__description--limited {
  max-width: 720px;
  margin: 0 auto; }

.message__error p {
  font-size: 14px;
  color: #e33232;
  margin-top: -8px; }

.post__image__full {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 0 !important;
  float: none !important; }

.post__footer {
  clear: both; }

.pages {
  padding: 2px 16px; }
  @media (max-width: 440px) {
    .pages {
      padding: 0 8px 10px; }
      .pages span {
        font-size: .85rem; } }

.post .bxp .post__image__small {
  width: auto;
  float: left;
  margin-right: 16px; }
  @media (max-width: 530px) {
    .post .bxp .post__image__small {
      float: none; } }

.post .bxp.def-link {
  width: auto;
  display: initial; }

.post--solo > .full-post {
  padding-left: 0;
  padding-right: 0; }

.post--solo > .full-post > div {
  padding-left: 16px;
  padding-right: 16px;
  max-width: 720px;
  margin: 0 auto;
  color: #141414; }

.pages a {
  padding: 0 4px;
  display: inline-block;
  min-width: 24px;
  text-align: center;
  border-radius: 12px;
  line-height: 24px;
  font-size: 15px;
  background-color: #e33232;
  color: #fff;
  transition: background-color 0.2s; }
  .pages a:hover, .pages a:focus {
    background-color: #c50505; }
  .pages a i {
    color: #fff; }

.pages .link-current {
  color: #747474;
  padding: 0 4px;
  display: inline-block;
  min-width: 24px;
  text-align: center;
  border-radius: 12px;
  line-height: 24px;
  font-size: 15px;
  background-color: #ddd;
  color: #555; }

.ui-widget-header {
  background: #e33232 !important;
  border-color: #e33232 !important; }

.at-map {
  margin: 16px; }
  .at-map a, .at-map i {
    color: #fff; }
  .at-map a {
    display: block; }

.post__brand_logo {
  margin-left: 16px; }

.starv input {
  width: 24px;
  height: 24px;
  display: block;
  visibility: hidden; }

.star {
  position: relative;
  cursor: pointer;
  display: block; }
  .star:after {
    margin-top: -24px;
    width: 24px;
    height: 24px;
    display: block;
    position: relative;
    content: " ";
    font-family: "Font Awesome 5 Free", FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #141414;
    position: absolute;
    text-align: center;
    line-height: 24px;
    margin-left: -5px; }

.star--empty:after {
  content: "\f005"; }

.star--full:after {
  content: "\f005";
  color: #141414;
  font-weight: 900; }

.star--half:after {
  content: "\f5c0";
  font-weight: 900; }

.star--active:after {
  color: #e33232;
  content: "\f005";
  font-weight: 900; }

.post__brand-wrapper h1.post__title:hover, .post__brand-wrapper h1.post__title:focus {
  color: #141414; }

@media (max-width: 1024px) {
  .hidden-small {
    display: none; } }

.login__button .button, .login__button .bt, .login__button .sbm2, .login__button .sbm3, .login__button .green_but, .login__button .g_button {
  float: none; }

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  margin-bottom: 16px;
  height: 0; }

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.post .review__text .bxp img {
  width: auto;
  max-width: 100%; }

.left-fixed-menu li a .inactive {
  display: none; }

.left-fixed-menu li a .lazy-load {
  display: block; }

.full-post p:empty, .full-post div:empty, .full-post span:empty {
  display: none; }

.full-post ul, .full-post ol {
  margin: 0 auto;
  max-width: 720px;
  margin-bottom: 32px; }
  .full-post ul li, .full-post ol li {
    line-height: 30px;
    color: #141414; }

.full-post ol li:before {
  content: "" !important; }

#tab_zaly {
  padding: 16px;
  margin-bottom: -32px; }
  #tab_zaly > p {
    padding-bottom: 16px; }

.hall-title {
  font-weight: 700; }

.hall-description, .hall-comment {
  font-size: 0.875rem;
  color: #747474;
  padding-bottom: 16px; }

.tel a {
  font-weight: 600; }

.fb_iframe_widget span {
  height: 24px !important; }

.span-line #soclist li a {
  padding: 0 4px; }

.post--brand640 {
  max-width: 720px;
  margin: 0 auto;
  margin-top: -32px; }

.zaly-640 .card__title {
  margin-bottom: 0;
  margin-top: 16px;
  margin-left: 16px;
  padding-right: 16px; }

.rest_name a {
  text-decoration: none !important; }

.social-list-contants .fa-facebook {
  color: #3b5998; }

.social-list-contants .fa-twitter {
  color: #00aced; }

.social-list-contants .fa-google-plus {
  color: #db3236; }

.social-list-contants .fa-vk {
  color: #45668e; }

.social-list-contants .fa-youtube {
  color: #ff0000; }

.social-list-contants .fa-instagram {
  color: #c13584; }

.social-list-contants .fa-foursquare {
  color: #2d5be3; }

.post .raw-text {
  padding: 16px 0 0 0; }
  @media (max-width: 620px) {
    .post .raw-text a, .post .raw-text span {
      font-size: 0.875rem; } }

.floated {
  float: left;
  margin-right: 16px; }
  @media (max-width: 620px) {
    .floated {
      float: none; } }

.at_map {
  margin: 16px; }
  .at_map a, .at_map i {
    color: #fff; }

.span-title {
  font-size: 0.875rem;
  color: #747474; }

.post--solo .bxp.def-link {
  position: relative;
  display: block; }
  .post--solo .bxp.def-link > .post__image__small {
    float: none;
    width: 100%;
    margin: 0; }

#guestbook_list h4 {
  padding-left: 16px; }

.brand__nominant a:hover {
  color: #e33232; }

.brand__visit, .brand__nominant {
  line-height: 24px; }

.frm {
  width: 100%;
  float: none; }
  @media (max-width: 1024px) {
    .frm {
      clear: both;
      float: none; } }
  .frm label {
    font-size: 14px; }
  .frm input[type=radio]:checked ~ label {
    color: #141414; }

#map_osm {
  z-index: 1;
  height: 300px; }

@media (max-width: 1024px) {
  .ext__btn {
    display: inline-block;
    margin-bottom: 12px; }
    .ext__btn .button--orange, .ext__btn .sbm3, .ext__btn .red_but {
      margin-top: 0;
      margin-left: 6px; }
  .centered .ext__sort {
    padding-bottom: 16px; } }

#forvard_step1, #forvard_step2, #forvard_step3 {
  margin: 16px 0 0 3px; }

#ref_filtr {
  float: right; }

#kaj {
  height: 40px;
  background: #e33232;
  color: #fff;
  display: inline-block;
  line-height: 40px;
  padding: 0 16px;
  transition: all 0.2s; }
  #kaj i {
    color: #fff; }
  #kaj:hover, #kaj:focus {
    background: #c50505; }

.span-line-1 .simple {
  margin: 0 0 -3px 4px; }

.b-right .span-wrapper {
  padding-top: 10px; }

.ico-map {
  font-size: 14px; }
  .ico-map i {
    width: 20px;
    margin-left: -24px; }

.money img {
  display: inline-block;
  margin-bottom: -4px; }

.map_obj_info {
  font-size: 12px;
  color: #747474; }

.l-name {
  font-weight: 700; }

.bottom-table {
  width: 100%;
  padding-top: 6px; }

.l-separator {
  margin: 4px 0; }

@media (min-width: 1025px) {
  .advanced {
    display: block !important; } }

.post__closed {
  color: red;
  clear: both; }

.left-item {
  width: 100%; }

.controls-link, .ava-controls a {
  font-size: 12px;
  padding: 2px 4px; }

.plus2, .minus2 {
  margin-left: -24px;
  margin-bottom: 5px; }

@keyframes discount {
  0% {
    color: #747474;
    transform: scale(1); }
  50% {
    color: #e33232;
    transform: scale(1.5); }
  100% {
    color: #747474;
    transform: scale(1); } }

.animated-fa {
  color: #747474;
  animation: discount 2s infinite;
  float: left;
  margin-left: -19px;
  margin-top: 4px; }

.span-line-11 .bxp {
  float: left; }

.bt {
  margin-top: 16px; }

#boxplus .boxplus-dialog .boxplus-main {
  padding: 0px; }

#boxplus .boxplus-dialog > .boxplus-title {
  top: 38px !important; }

#boxplus .boxplus-dialog {
  border: none;
  background-color: transparent;
  top: 0;
  margin: 0; }

#boxplus .boxplus-background {
  background: rgba(0, 0, 0, 0.9); }

#boxplus .boxplus-dialog .boxplus-m {
  background: transparent !important; }

#boxplus .boxplus-viewer > .boxplus-thumbs > ul, .boxplus-rewind, .boxplus-forward {
  visibility: hidden; }

#boxplus .boxplus-thumbs > ul {
  padding: 0; }

#boxplus .boxplus-viewer > .boxplus-thumbs ul li {
  background: rgba(0, 0, 0, 0.6) !important; }

#boxplus .boxplus-thumbs > .boxplus-forward, #boxplus .boxplus-thumbs > .boxplus-rewind {
  background: rgba(255, 255, 255, 0.75); }

.boxplus-bottom {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  height: 36px;
  border-bottom: thin solid #747474;
  background: rgba(0, 0, 0, 0.9); }

#boxplus .boxplus-caption > .boxplus-text {
  margin: 0;
  padding: 10px 16px;
  color: #ccc; }

#boxplus .boxplus-controls > .boxplus-prev, #boxplus .boxplus-controls > .boxplus-next, #boxplus .boxplus-controls > .boxplus-start, #boxplus .boxplus-controls > .boxplus-stop, #boxplus .boxplus-controls > .boxplus-close {
  background: none;
  position: relative;
  cursor: pointer;
  width: 44px;
  height: 32px; }
  #boxplus .boxplus-controls > .boxplus-prev:before, #boxplus .boxplus-controls > .boxplus-next:before, #boxplus .boxplus-controls > .boxplus-start:before, #boxplus .boxplus-controls > .boxplus-stop:before, #boxplus .boxplus-controls > .boxplus-close:before {
    position: absolute;
    font-family: FontAwesome;
    width: 44px;
    height: 32px;
    text-align: center;
    font-size: 18px;
    color: #fff;
    line-height: 32px; }
    #boxplus .boxplus-controls > .boxplus-prev:before:hover, #boxplus .boxplus-controls > .boxplus-prev:before:focus, #boxplus .boxplus-controls > .boxplus-next:before:hover, #boxplus .boxplus-controls > .boxplus-next:before:focus, #boxplus .boxplus-controls > .boxplus-start:before:hover, #boxplus .boxplus-controls > .boxplus-start:before:focus, #boxplus .boxplus-controls > .boxplus-stop:before:hover, #boxplus .boxplus-controls > .boxplus-stop:before:focus, #boxplus .boxplus-controls > .boxplus-close:before:hover, #boxplus .boxplus-controls > .boxplus-close:before:focus {
      color: #ccc; }

#boxplus .boxplus-viewer > .boxplus-resizer > .boxplus-enlarge {
  background: none;
  position: relative;
  cursor: pointer;
  display: none !important; }

#boxplus .boxplus-controls > .boxplus-prev:before {
  content: "\f053"; }

#boxplus .boxplus-controls > .boxplus-next:before {
  content: "\f054"; }

#boxplus .boxplus-controls > .boxplus-close:before {
  content: "\f00d"; }

#boxplus .boxplus-controls > .boxplus-start:before {
  content: "\f04b"; }

#boxplus .boxplus-controls > .boxplus-stop:before {
  content: "\f04d"; }

#boxplus .boxplus-thumbs > .boxplus-forward, #boxplus .boxplus-thumbs > .boxplus-rewind {
  cursor: pointer;
  width: 44px; }
  #boxplus .boxplus-thumbs > .boxplus-forward:before, #boxplus .boxplus-thumbs > .boxplus-rewind:before {
    position: absolute;
    font-family: FontAwesome;
    font-size: 18px;
    width: 100%;
    line-height: 70px;
    text-align: center;
    color: #141414; }

#boxplus .boxplus-thumbs > .boxplus-forward:before {
  content: "\f054"; }

#boxplus .boxplus-thumbs > .boxplus-rewind:before {
  content: "\f053"; }

#menu_body .boxplus-title {
  margin-left: -75px !important; }

.image__circle img {
  overflow: hidden; }

.quote {
  padding-bottom: 32px;
  font-style: italic;
  color: #747474; }
  .quote * {
    font-style: italic;
    color: #747474; }
  .quote .post__title {
    padding: 0; }

.image__label {
  clear: left; }

#boxplus .boxplus-thumbs {
  height: 68px; }

.zoom-map {
  margin-right: 0; }
  .zoom-map i {
    margin-right: 8px;
    color: #fff; }

.make-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  top: 0;
  left: 0;
  z-index: 1000201; }
  @media (max-width: 1024px) {
    .make-modal {
      padding-bottom: 4px; } }
  .make-modal .map-other-restaurants-checkbox {
    height: 4vh;
    background-color: #fff;
    min-height: 27px;
    width: 94vw;
    margin: 5vh 3vw 0;
    padding: 16px 16px 32px 16px !important; }
    @media (max-width: 320px) {
      .make-modal .map-other-restaurants-checkbox {
        height: 5%;
        min-height: 60px;
        margin: 7vh 3vw 0;
        padding: 16px 16px 60px 16px !important; } }
  .make-modal #map_osm {
    height: 86vh;
    width: 94vw;
    margin: 0vh 3vw 0 3vw; }
    @media (max-width: 620px) {
      .make-modal #map_osm {
        height: 86vh; } }
    @media (max-width: 320px) {
      .make-modal #map_osm {
        height: 78vh; } }

.map-other-restaurants-checkbox {
  padding: 8px 16px 16px 16px !important; }
  @media (max-width: 320px) {
    .map-other-restaurants-checkbox {
      min-height: 60px !important;
      padding: 6px; } }

.group-slider, .group-box {
  display: inline-block; }
  .group-slider input, .group-box input {
    margin: 0; }

.group-box input {
  margin: 4px 0 4px 0;
  line-height: 30px;
  display: block;
  float: left; }

@media (max-width: 320px) {
  .group-box {
    width: 100%; }
    .group-box input {
      margin: 4px 4px 4px 0; } }

.group-slider input {
  display: block;
  float: left;
  margin: 0px 4px; }

@media (max-width: 320px) {
  .group-slider {
    width: 100%; }
    .group-slider input {
      margin: 0; } }

.l-logo {
  float: left;
  margin-right: 32px;
  margin-top: 8px;
  max-width: 75px; }
  .l-logo img {
    max-width: 100%; }

.ico-map {
  padding-left: 24px;
  line-height: 1.3rem; }

.make-modal-close {
  display: none;
  position: fixed;
  top: 1vh;
  right: 1vw;
  color: #fff;
  font-size: 26px !important;
  z-index: 1000202; }

.post .review__text img {
  width: auto;
  margin-bottom: 8px; }

@media (max-width: 1024px) {
  .category-decode {
    padding-top: 7px;
    margin-left: 0; } }

@media (max-width: 480px) {
  .title-flex {
    flex-wrap: wrap; }
  .title__logo {
    flex: auto;
    width: 100%; } }

.object__book {
  padding: 16px; }
  .object__book .guestbook {
    padding: 0;
    font-size: 14px;
    margin-top: -8px; }
  .object__book .form__field, .object__book .nonbox input[type="text"], .nonbox .object__book input[type="text"] {
    width: 320px;
    float: none; }
    @media (480px) {
      .object__book .form__field, .object__book .nonbox input[type="text"], .nonbox .object__book input[type="text"] {
        width: 280px; } }

.review__footer--flex {
  clear: both;
  margin-top: 8px; }

.ui-widget-content {
  background: #fff !important;
  max-height: 320px;
  overflow: auto; }

.ui-menu .ui-menu-item {
  list-style-image: none;
  padding: 4px; }

.ui-widget-content .ui-state-active {
  background: #fff;
  color: #e33232;
  font-weight: 400;
  border: none;
  width: 100%;
  height: 100%;
  padding: 4px; }

#guestbook_list, .guest-page, .otzyvy .l-posts__title {
  max-width: 720px;
  margin: 0 auto; }

.post--solo > .full__description {
  padding-left: 0;
  padding-right: 0; }

.page__description {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto; }
  .page__description u {
    text-decoration: none;
    font-weight: 700; }
  .page__description ul li {
    color: #141414; }

.post--brand .make-modal-close {
  display: none; }

.block-640.brand .button, .block-640.brand .bt, .block-640.brand .sbm2, .block-640.brand .sbm3, .block-640.brand .green_but, .block-640.brand .g_button {
  margin-left: 0; }

.scroll-top {
  z-index: 2; }

.notice-text {
  padding: 16px !important;
  color: #747474;
  font-size: 0.875rem; }

.red-notice {
  font-size: 0.875rem;
  padding-top: 8px;
  padding-bottom: 8px;
  display: block; }

.table-wrapper {
  padding: 16px;
  margin-bottom: 4px; }
  .table-wrapper table {
    border-collapse: collapse; }
  .table-wrapper .darker {
    background: #ddd; }
  .table-wrapper a {
    text-decoration: none; }
  @media (max-width: 620px) {
    .table-wrapper {
      padding: 8px 5px; } }
  @media (max-width: 480px) {
    .table-wrapper {
      padding: 8px 3px; }
      .table-wrapper td, .table-wrapper a, .table-wrapper span {
        font-size: 0.78rem; } }
  .table-wrapper td {
    padding: 4px 0; }
  .table-wrapper tr td:first-child, .table-wrapper tr td:last-child {
    padding: 4px 8px; }

.l-separator {
  margin: 4px 0 12px 0; }

@media (max-width: 480px) {
  .notice-text {
    padding: 12px !important; } }

.rating-select {
  height: 62px;
  padding: 0 16px; }

.form__field--wide {
  width: 240px;
  float: none; }

.answer-review {
  margin-bottom: 12px; }
  .answer-review a {
    font-size: 16px !important; }

.not-found {
  padding: 16px; }

.whole-text img {
  width: 100% !important;
  height: auto !important;
  float: none; }

.mar-top {
  margin-top: -16px;
  display: block; }

.mar-bottom {
  margin-bottom: -16px; }

.slick-slide img {
  width: 100% !important; }

.whole-text {
  line-height: 150%; }

.brand {
  clear: both; }

.name-decode, .name-add {
  padding-right: 16px; }
  @media (max-width: 620px) {
    .name-decode, .name-add {
      padding-right: 8px; } }
  @media (max-width: 480px) {
    .name-decode, .name-add {
      padding-right: 4px;
      font-size: 1.125rem; } }

.warning {
  padding: 16px 16px 0 16px;
  font-weight: 700; }

.img-rounded {
  border-radius: 50%;
  overflow: hidden; }

.leaflet-popup-content .table-wrapper {
  padding: 0; }

.category-decode a {
  padding-left: 8px;
  font-size: 16px; }

.post-news ul {
  margin: 0 auto;
  max-width: 720px; }

.small-container {
  float: left;
  position: relative; }
  .small-container .post__image__small {
    margin-top: 16px;
    margin-right: 16px;
    margin-left: 16px; }
  @media (max-width: 530px) {
    .small-container {
      float: none; }
      .small-container .post__image__small {
        margin-left: 0;
        margin-right: 0;
        width: 100%; } }
  .small-container .comment {
    left: 16px;
    width: calc(100% - 32px); }
    @media (max-width: 530px) {
      .small-container .comment {
        left: 0;
        width: 100%; } }

.float-fix {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.post-news .post__image__small {
  width: 290px !important; }
  @media (max-width: 530px) {
    .post-news .post__image__small {
      width: 100% !important; }
      .post-news .post__image__small .bxp {
        width: auto;
        margin-left: -16px;
        margin-right: -16px; } }

.image__circle {
  max-width: initial; }

.post__footer {
  padding-top: 16px; }

.post .post__description--wrapper p {
  padding: 0; }

.post--solo.post-news .image__circle {
  padding: 0; }
  .post--solo.post-news .image__circle img {
    margin-top: 0; }
  @media (max-width: 530px) {
    .post--solo.post-news .image__circle {
      padding: 0 0 16px; } }

.pages, .pages_count {
  clear: left; }

.quote-not {
  clear: both;
  padding-top: 16px; }

.cats .form__field--wide {
  width: 100%; }

.checkbox-group {
  padding-top: 16px; }

@media (max-width: 1024px) {
  .list-inline {
    display: flex;
    align-items: baseline; }
    .list-inline li {
      padding: 8px; }
      .list-inline li span {
        white-space: nowrap; }
  display: flex; }

.sort-control {
  padding: 16px;
  float: right; }

article {
  clear: both; }

.legend-wrapper .l-posts__title {
  float: left; }

#calc_content {
  width: 286px;
  table-layout: fixed;
  margin-bottom: 16px; }

.t-name {
  font-weight: 600; }

.table-border {
  height: 1px;
  background-color: #ddd; }

.table-separator {
  height: 12px; }

.gallery-news .photogalery {
  width: 500px !important;
  max-width: 100%; }

.gallery-news .gallery_wrapper {
  margin-top: -16px;
  position: initial; }

.at4-share .at4-share-count {
  color: #fff; }

.closed-fix {
  float: left;
  position: relative;
  overflow: hidden; }

.brand-image-align {
  max-width: 720px;
  margin: 0 auto; }

.floated1 a[href="#"] {
  padding-left: 16px; }

.closed-fix {
  margin-left: 16px;
  margin-top: 0; }
  @media (max-width: 530px) {
    .closed-fix {
      margin-top: 0;
      margin-left: 0;
      width: 100%; }
      .closed-fix img {
        width: 100%;
        max-width: initial;
        padding-bottom: 8px; } }

#at-share-dock, .atss-right {
  transition: all 0.2s; }

.mobile-arrow {
  position: fixed;
  bottom: 48px;
  left: 8px;
  opacity: 0;
  transition: all 0.2s;
  cursor: pointer;
  text-align: center;
  line-height: 32px;
  display: none;
  width: 32px;
  height: 32px; }
  @media (max-width: 979px) {
    .mobile-arrow {
      display: block; } }
  .mobile-arrow i {
    font-size: 18px;
    color: #747474; }

.down-m {
  transform: rotate(180deg); }

.at4-arrow {
  display: none !important; }

.desk-arrow {
  position: fixed;
  top: 35%;
  right: 48px;
  opacity: 0;
  transition: all 0.2s;
  cursor: pointer;
  text-align: center;
  line-height: 32px;
  display: none;
  width: 32px;
  height: 32px; }
  @media (min-width: 980px) {
    .desk-arrow {
      display: block !important; } }
  .desk-arrow i {
    font-size: 18px;
    color: #747474; }

.right-m {
  transform: rotate(180deg); }

.add-text {
  line-height: 1.5rem; }

.post-news > .add-text {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px; }
  .post-news > .add-text > p, .post-news > .add-text > span, .post-news > .add-text > a {
    padding-left: 0;
    padding-right: 0; }

.form--modern .form__dates .button, .form--modern .form__dates .bt, .form--modern .form__dates .sbm2, .form--modern .form__dates .sbm3, .form--modern .form__dates .green_but, .form--modern .form__dates .g_button {
  display: block;
  float: none;
  margin: 16px 0 0; }

#link a {
  color: #48caff;
  text-decoration: underline;
  margin-left: 0px;
  margin-top: 8px;
  display: block; }

#div_step1, #div_step2, #div_step3 {
  padding: 16px; }

.post__title {
  max-width: 100%; }

@media (max-width: 620px) {
  .l-badge {
    margin-bottom: 16px; }
  .thumb_up, .thumb_down {
    padding: 10px; }
  .controls-link, .ava-controls a {
    padding-left: 0;
    display: block; }
    .controls-link:first-child, .ava-controls a:first-child {
      margin-top: 4px; } }

#cupon {
  background: #fff; }
  #cupon td {
    padding: 4px;
    vertical-align: top; }

.places {
  clear: both; }

.show-filters {
  font-size: 16px;
  color: #1ba6de;
  text-decoration: underline;
  padding: 12px 16px 0;
  display: block;
  margin-bottom: -16px;
  font-size: 14px; }
  .show-filters:hover, .show-filters:focus {
    color: #4dc9fb; }

.red_but {
  text-align: center; }
  .red_but input {
    cursor: pointer; }

.options1 a:first-child {
  float: left;
  margin-right: 16px; }

.inp.form__field, .nonbox input.inp[type="text"] {
  width: 240px;
  float: none;
  margin-top: 16px; }

.frm-enc {
  padding: 16px 0; }

.encyclopedia #search_param {
  padding: 16px; }

@media (max-width: 530px) {
  .encyclopedia h1 {
    clear: both; } }

#cboxTitle {
  position: initial !important; }

.article-flex {
  display: flex; }
  @media (max-width: 620px) {
    .article-flex {
      display: block; } }

.post__left {
  width: 314px;
  flex: 0 0 314px; }

.show-pas {
  float: right; }

.generator {
  text-decoration: underline; }

.modal-diplom {
  cursor: pointer; }
  .modal-diplom span {
    transition: color 0.2s; }
  .modal-diplom:hover span, .modal-diplom:focus span {
    color: #e33232; }

.search-zero {
  font-weight: 700;
  font-size: 20px;
  margin-top: -32px; }

.page-profile textarea.form__field--wide {
  height: 120px; }

.video-flex, .article {
  padding: 0 16px; }

.overflow-fix {
  overflow-y: auto !important; }

#ava_small_row {
  display: none; }

@media (max-width: 620px) {
  .video-flex, .article {
    flex-direction: column;
    margin-bottom: 20px; }
  .video__title {
    align-self: center;
    margin: 8px auto; }
  .video__info, .article__info {
    width: 300px; } }

.post__no-res {
  clear: both;
  padding: 16px; }

.ext__selected {
  display: none; }

h1.l-posts__title, h2.card__title, h3.card__image__title, h2 .l-posts__title, h2.l-posts__title {
  font-weight: 400; }

.header {
  /*position: fixed */
  position: absolute;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%; }
  .header .modal-helper {
    font-weight: 700;
    padding: 8px 8px 8px 0;
    background-color: #fff;
    max-width: 270px; }

.main-title {
  text-align: center;
  font-size: 2rem;
  font-weight: 700; }
  .main-title:after {
    display: none; }

.choose-restaurant li a {
  font-size: 15px;
  text-decoration: underline; }
  .choose-restaurant li a:hover {
    color: #e33232;
    text-decoration: none !important; }

#cboxOverlay {
  background: rgba(0, 0, 0, 0.65) !important; }
  #cboxOverlay .choose-restaurant li {
    width: 100%;
    height: auto; }

.small-edit {
  margin-top: 40px; }
  .small-edit #preview {
    position: absolute;
    right: 25px;
    top: 60px; }

.radio-group.mb {
  margin-bottom: 5px; }

#map_osm {
  pointer-events: none;
  user-select: none; }
  #map_osm img, #map_osm .leaflet-marker-icon.leaflet-interactive, #map_osm .leaflet-image-layer.leaflet-interactive, #map_osm .leaflet-pane > svg path.leaflet-interactive {
    pointer-events: none;
    user-select: none; }
  #map_osm .leaflet-marker-icon.leaflet-div-icon-red {
    height: 14px !important;
    width: 14px !important; }

.brands-list {
  pointer-events: auto !important;
  user-select: initial !important; }

.map-other-restaurants-checkbox .group-slider {
  pointer-events: none;
  user-select: none; }

.make-modal .map-other-restaurants-checkbox .group-slider {
  pointer-events: initial;
  user-select: initial; }

.make-modal #map_osm {
  pointer-events: initial !important;
  user-select: initial !important; }
  .make-modal #map_osm img {
    pointer-events: initial !important;
    user-select: initial !important; }
  .make-modal #map_osm .leaflet-marker-icon.leaflet-interactive, .make-modal #map_osm .leaflet-image-layer.leaflet-interactive, .make-modal #map_osm .leaflet-pane > svg path.leaflet-interactive {
    pointer-events: auto !important;
    user-select: initial !important; }

.fa-comments-o, .fa-picture-o, .fa-comments, .minus2:before, .plus2:before {
  color: #e33232 !important; }
  .fa-comments-o i:hover, .fa-comments-o i:focus, .fa-picture-o i:hover, .fa-picture-o i:focus, .fa-comments i:hover, .fa-comments i:focus, .minus2:before i:hover, .minus2:before i:focus, .plus2:before i:hover, .plus2:before i:focus {
    color: #c50505 !important; }

.minus2:before, .plus2:before {
  border: thin solid #e33232; }

#otherRestCheckboxSlider {
  width: 120px; }

.addthis_block_padding {
  padding-top: 16px !important; }

@media (max-width: 480px) {
  .list_full {
    font-size: 0.85rem; } }

.deliv_ag_logo {
  height: 100px;
  margin-bottom: 4px; }

.for_solid {
  font-size: 0.85em; }

.ico-pad {
  padding-right: 3px; }
  @media (max-width: 440px) {
    .ico-pad i {
      font-size: 0.8rem; } }

.menu-cart_flex {
  display: flex;
  justify-content: center; }

.cart__item_flex div {
  width: 100% !important;
  border: 0px solid #cccccc !important; }

.cart_flex {
  flex-wrap: wrap;
  padding: 0 !important;
  justify-content: space-evenly; }
  .cart_flex div {
    display: flex;
    justify-content: center;
    width: 47.5%;
    border: 1px solid #cccccc;
    margin-bottom: 10px;
    padding: 5px;
    flex-wrap: wrap;
    align-content: space-between; }
    @media (max-width: 440px) {
      .cart_flex div {
        width: 95% !important; } }

.cart__col--name_flex {
  justify-content: left !important; }
  .cart__col--name_flex a img {
    width: 100vw; }

.star-description {
  padding-top: 10px;
  font-size: 0.8rem; }

.mrg-top-16 {
  margin-top: 16px !important; }

.message-top {
  color: #e33232; }

pwa-install::part(openButton) {
  text-align: left;
  background: #e33232;
  margin: 8px 15px;
  font-size: 1.05rem; }

@media (max-width: 1600px) {
  pwa-install::part(installModal) {
    padding-top: 10px; } }

@media (max-width: 800px) {
  pwa-install::part(installModal) {
    padding-top: 50px; } }

@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-max-device-pixel-ratio: 4) {
  pwa-install::part(installModal) {
    padding-top: 60px; } }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.gallery_wrapper {
  max-width: 100%;
  overflow: hidden; }

.photogalery .cont {
  width: 100% !important;
  border: 0 !important;
  background: transparent !important; }

.photogalery {
  width: 100% !important; }

.photogalery .contentplus {
  width: 100% !important;
  float: none; }

.photogalery .scrollplus {
  width: 490px !important;
  clear: both !important;
  margin: 0 auto !important;
  float: none !important; }

.control-left {
  position: absolute;
  left: -8px;
  top: 50%;
  margin-top: -22px; }
  .control-left > a {
    padding: 16px; }

.control-right {
  position: absolute;
  right: -8px;
  top: 50%;
  margin-top: -22px; }
  .control-right > a {
    padding: 16px; }

.block-scroll {
  width: 20px;
  height: 10px;
  display: block;
  background: #141414; }

.scroll {
  display: none !important; }

.addthis-items {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.addthis-item {
  padding-bottom: 8px; }

.more-link-list li {
  padding-bottom: 12px; }
  .more-link-list li a {
    color: #141414;
    transition: color 0.2s; }
    .more-link-list li a:hover, .more-link-list li a:focus {
      color: #e33232; }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.city-block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 12px; }
  .city-block__item a {
    transition: color 0.2s;
    line-height: 24px;
    float: left; }
  .city-block__item:hover a, .city-block__item:focus a {
    color: #e33232; }

.scroll-top {
  right: 8px;
  bottom: 48px;
  width: 44px;
  height: 44px;
  background-color: #999;
  opacity: 0.5;
  transition: opacity 0.2s;
  text-align: center;
  position: fixed;
  cursor: pointer; }
  .scroll-top:hover, .scroll-top:focus {
    opacity: 0.85; }
  .scroll-top .fa {
    font-size: 30px !important;
    color: #fff;
    display: block;
    height: 44px;
    line-height: 44px; }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.container {
  width: 100%;
  display: block; }

.group {
  position: relative;
  width: 100%;
  margin-bottom: 34px; }
  .group input {
    border: none;
    border-bottom: 1px solid #333;
    padding: 10px;
    display: block;
    width: 100%; }
    .group input:focus {
      outline: none; }
    .group input:focus ~ label, .group input:valid ~ label {
      top: -20px;
      font-size: 14px;
      color: #e33232; }
  .group label {
    position: absolute;
    color: #333;
    top: 12px;
    left: 0;
    transition: 0.2s ease all; }

.bar {
  position: relative;
  display: block;
  width: 100%; }
  .bar:before {
    content: "";
    position: absolute;
    left: 50%;
    right: 50%;
    bottom: 0;
    background: #e33232;
    height: 4px;
    transition: left 0.2s ease-out, right 0.2s ease-out; }

input:focus ~ .bar:before {
  left: 0;
  right: 0; }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.calendar {
  padding: 32px 16px; }
  @media (min-width: 1141px) {
    .calendar {
      display: block !important; } }
  .calendar__header span {
    color: #747474;
    font-size: 0.875rem; }
  .calendar__body {
    display: flex;
    justify-content: space-between;
    padding: 4px 0; }
    @media (max-width: 1400px) and (min-width: 1141px) {
      .calendar__body {
        margin-left: -16px;
        margin-right: -16px; } }
    .calendar__body span {
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 24px;
      display: block; }
      .calendar__body span a {
        display: block;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        background-color: transparent;
        transition: all 0.2s;
        color: #fff;
        font-size: 0.75rem; }
    .calendar__body .plaincalendar2 a {
      background-color: #f38f8f;
      color: #fff; }
      .calendar__body .plaincalendar2 a:hover, .calendar__body .plaincalendar2 a:focus {
        background-color: #e33232; }
    .calendar__body .plaincalendarSV2 a {
      background-color: #e33232;
      color: #fff; }
      .calendar__body .plaincalendarSV2 a:hover, .calendar__body .plaincalendarSV2 a:focus {
        background-color: #c50505; }
    .calendar__body .plaincalendar1 a {
      background-color: #f38f8f;
      color: #fff; }
      .calendar__body .plaincalendar1 a:hover, .calendar__body .plaincalendar1 a:focus {
        background-color: #e33232;
        color: #fff; }
    .calendar__body .plaincalendarSV1 a {
      background-color: #e33232;
      color: #fff; }
      .calendar__body .plaincalendarSV1 a:hover, .calendar__body .plaincalendarSV1 a:focus {
        background-color: #c50505; }
    .calendar__body .highlighted a {
      background-color: #747474;
      cursor: default; }
      .calendar__body .highlighted a:hover, .calendar__body .highlighted a:focus {
        background-color: #747474; }
    .calendar__body span .calendar__current {
      font-weight: 700;
      background-color: #ddd;
      color: #555;
      cursor: default; }
      .calendar__body span .calendar__current:hover, .calendar__body span .calendar__current:focus {
        background-color: #ddd; }

.calendar__mobile {
  display: none; }

.calendar_arrows i {
  display: block;
  cursor: pointer;
  line-height: 24px; }

.bt.right {
  text-decoration: underline;
  font-size: 14px;
  line-height: 30px; }

@media (max-width: 1140px) {
  .calendar__mobile {
    display: block;
    padding: 16px 16px 0;
    cursor: pointer; }
  .calendar {
    display: none;
    padding: 16px;
    margin: -26px 0 0 5px;
    border: thin solid #ddd;
    z-index: 1;
    position: absolute;
    background: #fff;
    margin-top: 8px;
    box-shadow: 0 0 0 0 rgba(0, 1, 2, 0.1); }
    .calendar__header {
      position: absolute;
      height: 100%;
      margin-top: -14px; }
      .calendar__header span:last-child {
        position: absolute;
        bottom: 4px;
        width: 120px; }
    .calendar__body {
      display: flex;
      justify-content: flex-start;
      padding: 8px;
      position: relative;
      flex-wrap: wrap;
      width: 190px; }
      .calendar__body span {
        width: 25%;
        height: auto;
        text-align: center;
        line-height: 24px;
        display: block;
        padding: 4px 9px; }
        .calendar__body span a {
          display: block;
          height: 24px;
          width: 24px;
          border-radius: 50%;
          background-color: transparent;
          transition: all 0.2s;
          font-size: 0.75rem; } }

.left-fixed-menu {
  position: absolute;
  top: 16px;
  background: #fff;
  transition: all 0.05s;
  max-width: 19.2%;
  margin-left: -21%;
  max-height: 90vh;
  overflow: auto; }
  @media (max-width: 1024px) {
    .left-fixed-menu {
      position: absolute;
      overflow: auto;
      top: 0;
      margin-left: 0;
      max-width: 9000px;
      width: 100% !important;
      z-index: 2;
      padding: 0;
      margin-left: -17px !important;
      border-left: none;
      border-right: none; }
      .left-fixed-menu .flex-mobile {
        display: flex;
        flex-wrap: no-wrap; }
      .left-fixed-menu .menu-servises, .left-fixed-menu .menu-servises .services {
        margin-top: 0;
        border-top: none; }
      .left-fixed-menu .menu-servises {
        margin-bottom: 1px; } }
  @media (max-width: 1024px) and (max-width: 620px) {
    .left-fixed-menu {
      margin-left: -4px !important; } }
  @media (max-width: 1024px) {
      .left-fixed-menu .post__brand_logo {
        display: none; }
      .left-fixed-menu #tabs_bottom, .left-fixed-menu .services {
        display: flex;
        white-space: nowrap;
        max-width: 9000px;
        width: max-content;
        padding: 0 8px;
        float: left;
        height: 41px; }
        .left-fixed-menu #tabs_bottom li a span, .left-fixed-menu .services li a span {
          padding: 12px 4px 12px 0;
          font-size: 0.875rem;
          margin-right: 4px; }
        .left-fixed-menu #tabs_bottom li, .left-fixed-menu #tabs_bottom li a, .left-fixed-menu .services li, .left-fixed-menu .services li a {
          display: block; } }
  .left-fixed-menu li span {
    padding: 4px 0;
    display: block; }

.left-stickyhead {
  position: fixed;
  top: 66px;
  transition: all 0.05s; }
  @media (max-width: 1024px) {
    .left-stickyhead {
      max-width: 200%;
      top: 44px;
      width: 100% !important;
      z-index: 2;
      margin-left: -17px !important; } }
  @media (max-width: 1024px) and (max-width: 620px) {
    .left-stickyhead {
      margin-left: -4px !important; } }

.l-site {
  position: relative; }

.post__brand_logo {
  margin-left: 0;
  margin-bottom: 16px; }
  .post__brand_logo img {
    width: 100%; }

.logo__header--mobile, .title__logo--mobile {
  display: none; }

.full-padding {
  margin-top: 0; }

@media (max-width: 1024px) {
  .full-padding {
    margin-top: 50px; }
  .l-site--right.full-flex {
    flex: 0 0 100%;
    margin: 0 auto; }
  .logo__header--mobile {
    display: inline-block;
    max-width: 80px;
    margin-right: 8px; }
  .title__logo--mobile {
    display: inline-block;
    font-size: 1.25rem;
    font-weight: 700; } }

.left-fixed-menu {
  z-index: 1;
  background-color: #fff;
  border: thin solid #ddd;
  min-height: 10px;
  box-shadow: 0 0 0 0 rgba(0, 1, 2, 0.1);
  padding: 16px; }
  @media (max-width: 1024px) {
    .left-fixed-menu {
      padding: 0; } }

.filters-toggle {
  height: 44px;
  width: 44px;
  position: absolute;
  left: 66px;
  z-index: 3;
  cursor: pointer;
  text-align: center;
  display: none; }
  .filters-toggle i {
    color: #fff;
    font-size: 32px;
    text-align: center;
    line-height: 44px; }

@media (max-width: 1024px) {
  .filters-toggle {
    display: block; }
  .left-fixed-menu .advanced {
    display: none; } }

.menu-delivery .l-site--left {
  visibility: hidden !important; }

.settings-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  color: #747474;
  cursor: pointer;
  padding: 8px; }
  .settings-icon:hover, .settings-icon:focus {
    color: #141414; }

.settings-popup {
  display: none;
  position: absolute;
  top: 40px;
  box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.5);
  right: 8px;
  background: #fff;
  border: thin solid #ccc;
  padding: 16px 12px;
  width: 93%;
  max-width: 300px; }
  .settings-popup .ext__sort:nth-child(1) {
    border: 0;
    margin-top: 8px;
    padding-top: 0; }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.frame_block {
  padding: 16px;
  margin: 16px -16px 0 -16px;
  background: #f9f7ff; }

.rating1 {
  display: flex;
  flex-wrap: wrap;
  padding-top: 16px; }
  .rating1__title {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem; }
  .rating1__item {
    width: 50%;
    display: flex; }
    @media (max-width: 620px) {
      .rating1__item {
        width: 100%; } }
  .rating1 .voted-info, .rating1 .voted-name {
    display: block;
    width: 100%; }
  .rating1 .voted-info, .rating1 .voted-info span {
    font-size: 14px;
    color: #8e8e8e; }
  .rating1__left {
    min-width: 120px;
    align-self: center; }
  .rating1__right {
    display: flex;
    flex-wrap: wrap; }

.rating__value--custom {
  font-size: 32px;
  color: #1ba6de;
  padding: 0 8px 0 16px; }

.rating_voters {
  font-size: 1rem;
  color: #bbb; }

.post__brand .post-tittle {
  font-size: 1.5rem; }
  .post__brand .post-tittle:hover, .post__brand .post-tittle:focus {
    color: #141414; }
  @media (max-width: 620px) {
    .post__brand .post-tittle {
      font-size: 1.25rem; } }

.rating-block {
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
  width: 100%; }

.rating-wrapper {
  padding: 8px;
  width: 50%;
  border-bottom: thin solid #ddd;
  margin-bottom: 12px; }
  @media (max-width: 620px) {
    .rating-wrapper {
      width: 100%; } }

.quest__title {
  font-weight: 700; }

.quest__date {
  font-size: 14px;
  color: #747474; }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.block-640 {
  max-width: 720px;
  margin: 0 auto; }

.brand {
  padding: 16px; }
  .brand__description, .brand p {
    padding: 0; }
  .brand__rating-info {
    padding-top: 16px; }
  .brand__visit, .brand__nominant {
    display: block; }
  .brand__image {
    clear: both; }

.rest {
  display: block;
  line-height: 24px;
  padding-left: 24px; }
  .rest a {
    text-decoration: underline; }
    .rest a:hover {
      color: #c50505;
      text-decoration: none; }
  .rest i {
    width: 28px;
    max-width: 24px;
    margin-left: -24px;
    color: #444; }
  @media (max-width: 480px) {
    .rest {
      font-size: 0.875rem; } }

.post__free i, .post__open i {
  color: #444; }

.tel {
  padding: 0; }

.place {
  padding: 16px 0 8px 0; }

.rest_div {
  padding: 8px 0 16px 0; }

.information {
  clear: both; }
  .information__add {
    padding-bottom: 32px; }
    .information__add > div > a {
      padding-left: 16px; }
  .information__list, .page__description ul, .full-post ul, .full-post ol, .ext__selected {
    padding-left: 32px; }
    .information__list li, .page__description ul li, .full-post ul li, .full-post ol li, .ext__selected li {
      line-height: 24px;
      position: relative; }
      @media (max-width: 480px) {
        .information__list li, .page__description ul li, .full-post ul li, .full-post ol li, .ext__selected li {
          font-size: 0.875rem; } }
      .information__list li:before, .page__description ul li:before, .full-post ul li:before, .full-post ol li:before, .ext__selected li:before {
        content: "•";
        color: #e33232;
        line-height: 24px;
        position: absolute;
        left: 0;
        font-size: 32px;
        margin-left: -16px; }
  .information__title {
    font-size: 1.5rem;
    padding-bottom: 16px; }
  .information__tags {
    display: flex;
    flex-wrap: wrap; }
  .information__tag {
    display: block;
    padding: 8px;
    margin: 2px;
    border: thin solid #e33232;
    color: #e33232; }

.tab_ob {
  min-height: 1px; }

#tab_contact {
  padding: 32px 0 16px 0; }

.object__address {
  padding-left: 16px;
  padding-right: 16px; }
  .object__address .form__select {
    float: none;
    width: 260px; }
  .object__address .guestbook {
    font-size: 0.875rem;
    color: #747474;
    padding-bottom: 4px;
    max-width: 100%;
    padding-left: 0; }

.post .add_review, .form__review {
  padding: 16px;
  max-width: 100%; }

.brend__image--huge img {
  width: 100%; }

.zaved__title {
  margin-bottom: 0;
  font-weight: 400; }

.name-decode {
  font-size: 1.5rem;
  display: inherit; }

.name-add {
  font-size: 1.5rem;
  font-weight: 400;
  color: #747474; }

.category-decode {
  display: block;
  color: #747474;
  font-weight: 400;
  font-size: 0.875rem;
  padding-top: 4px; }

@media (max-width: 1024px) {
  .name-decode {
    font-size: 1.25rem; }
  .name-add {
    font-size: 1.25rem; }
  .title__logo {
    display: inline-block;
    vertical-align: top;
    flex: 1; }
    .title__logo .post__free, .title__logo .post__open {
      font-size: 0.75rem;
      font-weight: 400;
      padding-top: 4px;
      display: block;
      float: right;
      padding-left: 8px;
      font-size: 14px; }
  .post__free, .post__open {
    display: none; }
  .post__info--padding {
    display: none; }
    .post__info--padding .status1, .post__info--padding .status2, .post__info--padding .status3, .post__info--padding .status4, .post__info--padding .status5 {
      display: none; }
  .post__title.left {
    width: 100%; } }

@media (min-width: 1025px) {
  .title__logo .post__free, .title__logo .post__open {
    display: none;
    font-size: 14px; }
  .post__free {
    display: block; } }

.tel {
  display: block; }

.rest-comment {
  display: inline-block; }

.span-wrapper .social-list-contants {
  display: flex; }

#tab_video {
  padding: 0 16px; }

.video-flex, .article {
  display: flex; }

.video__info, .article__info {
  align-self: center;
  padding-left: 16px; }

.video__link, .article__title {
  font-size: 1.25rem;
  font-weight: 600;
  display: block;
  padding-bottom: 10px; }

.video__date, .article__date, .video__stats {
  font-size: 0.875rem;
  color: #747474;
  display: block;
  line-height: 1.5rem; }

.video-flex .video__title, .article .video__title {
  max-width: 300px;
  min-width: 300px; }

.video-flex .video__image, .article .video__image {
  width: 300px; }

.article {
  margin-bottom: 8px;
  padding-bottom: 8px; }
  .article__image {
    max-width: 150px;
    min-width: 150px; }
    .article__image img {
      width: 100%; }

@media (max-width: 1024px) {
  .title-flex {
    float: none;
    max-width: 100%;
    display: flex; }
  .right-snap {
    text-align: right; } }

.b-flex {
  display: flex; }
  @media (max-width: 620px) {
    .b-flex {
      flex-wrap: wrap; } }

.b-body {
  padding: 16px; }
  .b-body .separator {
    margin-top: 24px; }

.b-left {
  flex: 0 0 300px; }
  @media (max-width: 620px) {
    .b-left {
      flex: auto;
      width: 100%; } }
  .b-left img {
    width: 100%; }

.b-right {
  padding-left: 16px;
  color: #e33232;
  flex: 1; }
  @media (max-width: 620px) {
    .b-right {
      flex: auto;
      width: 100%;
      padding-left: 0; } }

.b-header-flex {
  display: flex; }

.b-header__status {
  padding-top: 4px; }

.b-header__title {
  font-size: 1.25rem; }

.b-header__category {
  color: #747474;
  font-size: 0.875rem; }

.ext__selected li {
  font-size: 14px;
  color: #747474;
  font-weight: 300;
  line-height: 20px; }

.ext__search {
  font-size: 0.75rem;
  color: #747474; }

.ext__row {
  padding-top: 12px; }
  @media (max-width: 1024px) {
    .ext__row {
      padding: 4px 8px;
      min-width: 250px; } }
  .ext__row:empty {
    padding-top: 0; }
  .ext__row a:before {
    content: "\f101";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    margin-right: 4px;
    font-size: 0.85em; }

.ext__sort {
  font-size: 0.75rem;
  padding-top: 16px;
  padding-bottom: 4px;
  margin-top: 16px;
  color: #747474;
  border-top: thin solid #ddd;
  clear: both;
  display: block; }

@media (max-width: 1024px) {
  .advanced {
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 16px; } }

@media (max-width: 480px) {
  .advanced {
    padding-left: 8px; } }

.cl {
  clear: both; }

.ext__list {
  overflow: hidden;
  padding-top: 55px; }

.ext__list__item {
  padding: 4px; }

.span-line {
  padding-left: 24px; }
  .span-line .span-title i {
    font-size: 16px;
    width: 20px;
    max-width: 24px;
    margin-left: -24px;
    color: #747474; }
  @media (max-width: 480px) {
    .span-line {
      font-size: 0.875rem; } }

.left-fixed-menu .form__field, .left-fixed-menu .nonbox input[type="text"], .nonbox .left-fixed-menu input[type="text"] {
  width: 100%;
  float: none;
  margin-bottom: 8px;
  height: 30px;
  line-height: 30px; }

.show-all-link {
  float: right; }
  @media (max-width: 480px) {
    .show-all-link {
      display: block;
      float: none; } }

.b-header__logo {
  float: left;
  padding-right: 8px; }

.strike {
  text-decoration: line-through; }

.object-description ul {
  max-width: 720px;
  margin: 0 auto;
  padding-left: 24px; }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.copy-link__helper {
  display: none;
  position: relative; }
  .copy-link__helper:before {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #e33232;
    content: " ";
    display: block;
    position: absolute; }

.copy-link__raw {
  display: none !important; }

.copy-link__notice {
  position: absolute;
  margin-top: 6px;
  border: thin solid #e33232 !important;
  background: #e33232;
  color: #fff !important;
  white-space: nowrap;
  display: block;
  padding: 8px 4px;
  right: -20px;
  left: auto; }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.nonbox {
  position: absolute;
  display: none;
  background: #fff;
  border: thin solid #ddd;
  width: 320px;
  padding: 16px;
  z-index: 1; }
  .nonbox h3, .nonbox input[type="text"] {
    width: 100%; }
  .nonbox h3 {
    padding-bottom: 8px; }
  .nonbox input[type="button"] {
    margin-top: 12px; }

.form__field--full {
  width: 100%;
  float: none; }

.form__field--textarea {
  height: 170px; }

.txt {
  margin: 8px 0 16px; }
  .txt label {
    font-size: 0.875rem;
    color: #747474;
    display: block;
    padding: 8px 0 4px 0; }

.sbm3, .sbm2 {
  float: left;
  margin-top: 8px;
  cursor: pointer; }

.sbm3 {
  margin-left: 8px; }

.form__buttons {
  position: absolute;
  bottom: 16px;
  right: 32px; }
  @media (max-width: 1024px) {
    .form__buttons {
      right: auto;
      bottom: -40px;
      left: 0; } }
  .form__buttons a {
    cursor: pointer; }
    @media (max-width: 1024px) {
      .form__buttons a {
        display: inline-block;
        padding: 8px; } }
    .form__buttons a:hover i, .form__buttons a:focus i {
      color: #e33232; }

.controls-txt {
  position: relative; }
  @media (max-width: 1024px) {
    .controls-txt {
      margin-bottom: 50px; }
      .controls-txt .nonbox {
        right: auto !important;
        top: 30px !important;
        width: 300px; } }

.controls-list__item {
  display: inline-block; }
  .controls-list__item .social__item {
    border-color: #fff; }

.comments-description {
  padding: 16px; }

.comment-notice {
  font-size: 0.875rem;
  color: #747474;
  margin-bottom: 16px; }

.review-c-wrapper {
  padding: 0; }
  .review-c-wrapper .comment-notice {
    padding: 0 16px; }
  .review-c-wrapper .kaj {
    margin-left: 16px; }
  .review-c-wrapper form {
    padding: 16px; }

.icon {
  font-size: 10px;
  color: #fff; }

.icon:before {
  content: ' ';
  vertical-align: middle;
  display: inline-block;
  position: absolute;
  background-image: url("svg/sprite.svg");
  background-repeat: no-repeat;
  background-size: 4.5em 45em; }

.no-svg .icon:before {
  background-image: url("svg/sprite.png"); }

.icon.zb:before, .icon.bl:before {
  background-position: 0em 0em;
  width: 4.5em;
  height: 4.5em; }

.icon.v3t:before {
  background-position: 0em -4.5em;
  width: 4.5em;
  height: 4.5em; }

.icon.do:before {
  background-position: 0em -9em;
  width: 4.5em;
  height: 4.5em; }

.icon.op:before {
  background-position: 0em -13.5em;
  width: 4.5em;
  height: 4.5em; }

.icon.gz:before {
  background-position: 0em -18em;
  width: 4.5em;
  height: 4.5em; }

.icon.mo:before {
  background-position: 0em -22.5em;
  width: 4.5em;
  height: 4.5em; }

.icon.nv:before {
  background-position: 0em -27em;
  width: 4.5em;
  height: 4.5em; }

.icon.zs:before {
  background-position: 0em -31.5em;
  width: 4.5em;
  height: 4.5em; }

.icon.vr:before {
  background-position: 0em -36em;
  width: 4.5em;
  height: 4.5em; }

.icon.sz:before {
  background-position: 0em -40.5em;
  width: 4.5em;
  height: 4.5em; }

.services li {
  float: left;
  width: 44px;
  height: 44px;
  margin: 8px; }

.services {
  height: 52px; }

.menu-servises {
  margin-top: 12px;
  margin-bottom: 8px;
  border-top: thin solid #ddd; }
  .menu-servises .services {
    margin-top: 12px; }
  .menu-servises .icon {
    color: #141414;
    text-transform: capitalize;
    font-size: 16px; }
    .menu-servises .icon:before {
      width: 0;
      height: 0; }
  .menu-servises .services li {
    width: 100%;
    height: auto;
    margin: 0; }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.l-popup .leaflet-popup-content {
  margin: 0;
  margin: 12px; }
  @media (max-width: 480px) {
    .l-popup .leaflet-popup-content {
      margin: 6px; } }

.l-popup .leaflet-popup-content-wrapper {
  border-radius: 6px;
  border-bottom: 6px solid #e33232; }
  .l-popup .leaflet-popup-content-wrapper:after {
    display: block;
    height: 6px;
    background: #e33232;
    margin-bottom: -1px;
    margin-left: -1px;
    margin-right: -1px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px; }

.l-popup .leaflet-popup-tip {
  background: #e33232; }

.l-popup a, .l-popup .tel_numbs {
  font-size: 14px !important;
  opacity: 1; }
  .l-popup a:hover, .l-popup a:focus, .l-popup .tel_numbs:hover, .l-popup .tel_numbs:focus {
    opacity: 0.75; }

.l-popup .map_obj_info > div:nth-child(1), .l-popup .map_obj_info > div:nth-child(2) {
  margin-top: 8px !important; }

.leaflet-control-attribution a {
  font-size: 11px !important; }

@media (max-width: 360px) {
  .leaflet-popup-content {
    max-width: 280px; } }

.group-box {
  height: 28px; }

.group-box [type="checkbox"]:not(:checked),
.group-box [type="checkbox"]:checked {
  position: absolute;
  left: -9999px; }

.group-box [type="checkbox"]:not(:checked) + label,
.group-box [type="checkbox"]:checked + label {
  position: relative;
  padding-left: 1.95em;
  cursor: pointer; }

.group-box [type="checkbox"]:not(:checked) + label:before,
.group-box [type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1.25em;
  height: 1.25em;
  border: 1px solid #d8d8d8;
  background: #fff;
  border-radius: 4px; }

.group-box [type="checkbox"]:not(:checked) + label:after,
.group-box [type="checkbox"]:checked + label:after {
  content: '✔';
  position: absolute;
  top: .2em;
  left: 4px;
  font-size: 1.3em;
  line-height: .8;
  color: #e33232;
  transition: all .2s;
  font-family: Helvetica, Arial, sans-serif; }

.group-box [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0); }

.group-box [type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1); }

.group-box [type="checkbox"]:disabled:not(:checked) + label:before,
.group-box [type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd; }

.group-box [type="checkbox"]:disabled:checked + label:after {
  color: #999; }

.group-box [type="checkbox"]:disabled + label {
  color: #aaa; }

.group-box [type="checkbox"]:checked:focus + label:before,
.group-box [type="checkbox"]:not(:checked):focus + label:before {
  border: 2px dotted #c50505; }

.group-box label:hover:before {
  border: 2px solid #e33232; }

.ico-map i, .thumbs-up-alticon-, .post__count i, .brand > i, .span-line .span-title i, .post__open--show i {
  color: #444; }

.is-logo .l-logo {
  margin-right: 16px; }

.is-logo div.ico-map i {
  margin-left: 0; }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.l-badge {
  position: relative;
  overflow: hidden; }

.badge {
  width: 270px;
  padding: 16px 70px;
  position: absolute;
  text-align: center;
  color: #fff;
  margin: 48px;
  background-color: #1ba6de;
  right: -135px;
  top: -24px; }
  .badge--right {
    transform: rotate(45deg); }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.page-profile {
  padding: 16px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto; }
  .page-profile .form__field--wide {
    width: 100%; }

.captcha {
  padding-top: 20px; }

.label-group label, .label-group span {
  font-size: 14px; }
  .label-group label small, .label-group span small {
    font-size: 12px;
    color: #747474; }

.label-group label {
  color: #333;
  display: block;
  margin-bottom: 5px; }

.star-required {
  color: red;
  margin-right: 3px; }

.register-group {
  margin-top: 12px; }
  .register-group .message a {
    font-weight: 300;
    font-size: 13px; }
  .register-group .avatar {
    display: flex;
    align-items: flex-start; }
    .register-group .avatar img {
      max-width: 70px; }
  .register-group .label-group {
    display: flex;
    justify-content: left;
    align-items: center; }
    .register-group .label-group .flex label {
      margin-left: 3px;
      color: #333;
      font-weight: 300; }
    .register-group .label-group .show-pas {
      float: none !important; }
      .register-group .label-group .show-pas label {
        display: flex; }

.mail-block {
  padding-top: 16px; }

.ava-group {
  display: flex;
  padding-top: 8px; }
  .ava-group img {
    width: 64px;
    height: 64px;
    border-radius: 50%; }

.ava-controls {
  padding-left: 16px;
  align-self: center; }
  .ava-controls a {
    text-decoration: underline;
    font-size: 16px; }
  .ava-controls a:last-child {
    color: #e33232; }

.mail-title {
  text-align: center;
  font-weight: 600;
  font-size: 1.25rem;
  padding-top: 8px; }

.small-notice {
  clear: both;
  text-align: center; }
  .small-notice a {
    color: #747474;
    text-decoration: underline;
    font-size: 12px; }
    .small-notice a:hover, .small-notice a:focus {
      color: #e33232; }

.politics-text h2, .politics-text p {
  line-height: 1.25rem; }

.politics-text p {
  padding-top: 8px; }

.label-pass {
  margin-left: 10px; }

@media not all and (-webkit-min-device-pixel-ratio: 0) {
  .group-slider label {
    margin-top: -6px;
    display: block;
    float: left; } }

#jr_overlay {
  background: #f5f5f5 none repeat scroll 0% 0% !important;
  opacity: 1 !important; }

#jr_close {
  height: 1px !important;
  overflow: hidden !important; }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.menu-header__brand {
  display: inline-block;
  padding: 0 16px; }
  .menu-header__brand a {
    font-size: 18px;
    text-decoration: underline; }
  .menu-header__brand span {
    font-size: 16px;
    color: #747474; }
  @media (max-width: 480px) {
    .menu-header__brand a, .menu-header__brand span {
      font-size: 0.85rem; } }

.menu-header__date {
  text-align: right;
  padding: 0 16px;
  display: inline-block;
  float: right; }
  .menu-header__date i {
    color: #747474; }

.info_message {
  clear: both;
  line-height: 1.5rem;
  padding: 0 16px 16px;
  color: #747474;
  transition: all 0.2s; }
  @media (max-width: 480px) {
    .info_message {
      font-size: 0.85rem;
      padding-bottom: 8px; } }

.menu-header__top {
  margin-bottom: 16px; }

.cats {
  padding: 0 16px 16px;
  clear: both; }

.new {
  font-size: 10px;
  color: #e33232;
  display: block; }

.steps-form {
  max-width: 520px;
  width: 100%;
  margin: 0 auto; }

.subtitle {
  text-align: center;
  margin-bottom: 6px; }

.center {
  text-align: center; }

.group__label {
  display: inline-block;
  padding-right: 16px;
  color: #747474; }

.group__info {
  display: inline-block; }

.order-wrapper .group {
  padding-bottom: 8px;
  margin-bottom: 0;
  margin-top: 8px;
  border-bottom: thin solid #ddd; }

.group__buttons, .buttons-order {
  padding-top: 8px;
  display: flex;
  flex-wrap: wrap;
  margin-left: -4px;
  margin-right: -4px; }

.group__buttons input, .buttons-order input {
  margin: 4px 2px 0 2px;
  float: none;
  display: inline-block; }

.border-msg, .order_info {
  padding: 16px;
  margin: 8px 0 0 0;
  border: thin solid #ddd;
  background: #f5f5f5; }

.order_info {
  margin: 0 16px; }

.meals {
  text-align: center;
  font-weight: 700;
  padding: 16px 0 8px; }

.table-h {
  color: #747474;
  font-size: 14px; }

.td_ag {
  display: none; }

.td_ag.sel {
  display: inline; }

.delivery__item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; }

.item-d {
  text-align: left;
  width: 33.33333%;
  min-width: 120px;
  max-width: 200px;
  padding: 8px; }
  .item-d > div {
    display: inline-block !important; }
  .item-d > span {
    display: inline !important; }
  .item-d:first-child {
    padding-left: 0; }
  .item-d:last-child {
    padding-right: 0; }

#step2_info {
  height: 100px;
  padding-top: 8px; }

.td_pay {
  display: none; }

.td_pay.sel {
  display: inline; }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.rest_menu h2 {
  clear: both;
  padding: 16px 16px 4px;
  font-size: 1.20rem;
  padding-top: 16px; }

.cart, .cart_flex {
  padding-left: 16px;
  padding-right: 16px;
  clear: both; }
  @media (max-width: 480px) {
    .cart, .cart_flex {
      padding-left: 4px;
      padding-right: 4px; } }
  .cart__item, .cart__item_flex {
    clear: both;
    border-bottom: thin solid #ddd; }
  .cart__col {
    float: left;
    padding: 10px 2px; }
    @media (max-width: 620px) {
      .cart__col {
        font-size: 0.9rem; } }
    @media (max-width: 440px) {
      .cart__col {
        font-size: 0.8rem; } }
    @media (max-width: 320px) {
      .cart__col {
        font-size: 0.7rem; } }
    .cart__col--name, .cart__col--name_flex {
      width: 32%;
      padding-right: 4px; }
      .cart__col--name a, .cart__col--name_flex a {
        display: block; }
      @media (max-width: 480px) {
        .cart__col--name, .cart__col--name_flex {
          padding-right: 2px; } }
    .cart__col--content {
      width: 35%;
      padding-left: 4px;
      padding-right: 4px; }
      @media (max-width: 440px) {
        .cart__col--content {
          padding-right: 2px;
          padding-left: 2px;
          width: 34%; } }
    .cart__col--portion {
      width: 18%;
      padding-right: 4px;
      padding-left: 4px; }
      @media (max-width: 440px) {
        .cart__col--portion {
          padding-right: 2px;
          padding-left: 2px;
          width: 17%; } }
    .cart__col--price {
      width: 10%; }
      @media (max-width: 440px) {
        .cart__col--price {
          padding-right: 2px;
          padding-left: 2px;
          width: 10%; } }
    .cart__col--icon {
      width: 5%;
      height: 30px; }
      @media (max-width: 440px) {
        .cart__col--icon {
          padding-left: 2px;
          width: 7%; } }
      .cart__col--icon i {
        transition: color 0.2s;
        font-size: 20px; }
        @media (max-width: 480px) {
          .cart__col--icon i {
            font-size: 16px; } }
  .cart__item .cart__col--name, .cart__item_flex .cart__col--name, .cart__item .cart__col--name_flex, .cart__item_flex .cart__col--name_flex {
    font-weight: bold; }
  .cart__title {
    display: block;
    font-weight: bold;
    font-size: 1.25rem;
    padding: 0 4px 8px 0; }
    @media (max-width: 440px) {
      .cart__title {
        padding: 0 0 6px 3px;
        font-size: 0.85rem; } }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.cart-toggle {
  position: absolute;
  z-index: 3;
  right: 14px;
  padding: 21px;
  cursor: pointer; }
  .cart-toggle i {
    color: #fff;
    font-size: 24px; }
  @media (max-width: 1240px) {
    .cart-toggle {
      padding-top: 10px; } }

.calculator-modal {
  background-color: #fff;
  padding: 16px;
  width: 320px;
  border: thin solid #ddd;
  margin-top: -28px;
  box-shadow: 1px 5px 15px 2px rgba(0, 1, 2, 0.33);
  z-index: 4;
  right: 16px;
  position: absolute;
  display: none;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden; }
  @media (max-width: 366px) {
    .calculator-modal {
      right: 0; } }
  @media (max-width: 1240px) {
    .calculator-modal {
      top: 20px; } }
  .calculator-modal.stickyhead-right {
    position: fixed;
    margin-top: 50px;
    top: 0; }
    @media (max-width: 1240px) {
      .calculator-modal.stickyhead-right {
        margin-top: 36px; } }

.green_but {
  width: 141px; }
  .green_but input {
    background: transparent;
    color: #fff; }

.red_but {
  color: #fff;
  width: 100%;
  margin-top: 4px;
  cursor: pointer; }
  .red_but input {
    background: transparent;
    color: #fff; }

.red_but, .green_but {
  font-weight: 300;
  text-transform: uppercase;
  display: inline-block;
  width: 141px; }

.g_button {
  width: 141px;
  display: none; }

.portions {
  color: #747474;
  font-size: 0.875rem; }

.portions__input {
  width: 70px;
  border: thin solid #ddd;
  padding-left: 8px; }

.not-null {
  position: absolute;
  color: #fff;
  background-color: #1e9931;
  border-radius: 10px;
  height: 20px;
  min-width: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  padding: 0 4px;
  top: 13px;
  left: 38px; }
  @media (max-width: 1240px) {
    .not-null {
      top: 2px;
      left: auto;
      margin-left: -2px;
      left: initial;
      right: 6px; } }

.show-price {
  position: absolute;
  left: 38px;
  height: 20px;
  min-width: 20px;
  background-color: #1ba6de;
  padding: 0 4px;
  font-size: 12px;
  color: #fff;
  border-radius: 10px;
  line-height: 20px;
  top: 36px; }
  @media (max-width: 1240px) {
    .show-price {
      position: initial;
      top: 24px;
      float: left;
      height: 24px;
      line-height: 24px;
      border-radius: 12px;
      font-size: 14px;
      padding: 0 10px;
      margin-right: 8px;
      margin-top: 1px; } }

.inpt {
  width: 55px; }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.add-this-review {
  padding: 16px; }

.review-links {
  padding: 16px; }
  .review-links span {
    color: #747474; }

.whole-text {
  padding: 16px;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto; }

.small-720 {
  max-width: 720px;
  margin: 0 auto; }

.review-class .post__description p, .review-class .whole-text p {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }
  .review-class .post__description p:first-child, .review-class .whole-text p:first-child {
    padding-top: 0; }
  .review-class .post__description p:last-child, .review-class .whole-text p:last-child {
    padding-bottom: 0; }

.quote {
  max-width: 720px;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px; }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.order__steps {
  margin: 0 auto;
  text-align: center;
  padding: 16px; }
  .order__steps .step {
    position: relative;
    z-index: 2;
    display: inline-block;
    text-align: center;
    background: #000;
    width: 60px;
    height: 60px;
    font-weight: bold;
    border-radius: 50%;
    padding-top: 20px;
    transition: background 1000ms ease, color 1000ms ease;
    transition-delay: 800ms;
    background: linear-gradient(to right, #1ba6de 50%, #ededed 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    cursor: pointer; }
    .order__steps .step.active {
      background-position: left bottom;
      color: #fff; }
  .order__steps .step-reverse {
    transition-delay: 1ms; }
  .order__steps .step-separator {
    position: relative;
    z-index: 1;
    background: #444;
    height: 10px;
    width: 150px;
    margin: 0 -10px;
    display: inline-block;
    background: linear-gradient(to right, #1ba6de 50%, rgba(220, 220, 220, 0.5) 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background 1000ms ease; }
    .order__steps .step-separator.active {
      background-position: left bottom; }
  .order__steps .step-separator-reverse {
    transition-delay: 800ms; }

@media (max-width: 500px) {
  .order__steps .step-separator {
    width: 25%; } }

@media (max-width: 370px) {
  .order__steps .step-separator {
    width: 20%; } }

blockquote {
  font-size: 24px;
  font-style: italic;
  quotes: "“" "”" "‘" "’";
  padding: 30px 0 15px;
  font-family: Georgia;
  line-height: 130%; }

blockquote:before {
  font-family: georgia, arial;
  font-style: italic;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.15em;
  vertical-align: -0.4em;
  color: #e33232; }

blockquote:after {
  font-family: georgia, arial;
  font-style: italic;
  content: close-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-left: 0.15em;
  vertical-align: -0.7em;
  color: #e33232; }

/* Modal Base CSS */
.z-overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: 1ms opacity ease;
  background: rgba(0, 0, 0, 0.8); }

.z-modal {
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  opacity: 0;
  width: 94%;
  padding: 24px 20px;
  transition: 1ms opacity ease;
  transform: translate(-50%, -50%);
  border-radius: 2px;
  background: #fff; }

.z-modal.z-open.z-anchored {
  top: 20px;
  transform: translate(-50%, 0); }

.z-modal.z-open {
  opacity: 1;
  transition: 200ms top 200ms ease; }

.z-overlay.z-open {
  opacity: 1;
  transition: 200ms top 200ms ease; }

.z-modal.z-open {
  overflow-y: auto; }

/* Close Button */
.z-modal iframe {
  width: 100%; }

.z-close {
  font-size: 24px;
  font-weight: 700;
  line-height: 12px;
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px 7px 7px;
  cursor: pointer;
  color: #fff;
  border: 0;
  outline: none;
  background: #e74c3c; }

.z-close:hover {
  background: #c0392b; }

.z-overlay.fade-modal {
  display: block;
  opacity: 0; }

.z-modal.fade-modal {
  top: -300%;
  opacity: 1;
  display: block; }

.z-modal.fade-modal.z-open {
  top: 50%;
  transition: 200ms top 200ms ease; }

.z-modal.fade-modal.z-open.z-anchored {
  transition: 200ms top 200ms ease; }

.z-overlay.fade-modal.z-open {
  top: 0;
  transition: 200ms opacity ease;
  opacity: 1; }

.z-modal.fade-modal {
  transition: 200ms top ease; }

.z-overlay.fade-modal {
  transition: 200ms opacity 200ms ease; }

.standart {
  text-decoration: underline;
  transition: color 0.2s; }
  .standart:hover, .standart:focus {
    color: #e33232; }

@media (max-width: 500px) {
  .iframe {
    padding: 40px 0; }
  .caption {
    padding: 0 16px; } }

.z-modal.video {
  width: 720px !important;
  padding: 0px !important;
  display: flex;
  background-color: #000; }
  .z-modal.video video {
    max-height: 100%;
    margin: auto; }
  .z-modal.video .z-close {
    background: transparent;
    z-index: 100; }
  @media (max-width: 720px) {
    .z-modal.video video {
      max-width: 100%;
      vertical-align: top; } }

.link-effect, .span-wrapper .span-line a, .post__wrapper--full .post__info__link, .post__wrapper--full .post__info__link a, .full-post a, a.more, .form__link a, .post__description a, .tel a, .place a, .brand__nominant a, .options1 a, .bt.right, [data-readmore-toggle], .map_obj_info a name, .order_info a, .review-links a, .banket-name-brand {
  transition: color 0.2s;
  text-decoration: underline; }
  .link-effect:hover, .span-wrapper .span-line a:hover, .post__wrapper--full .post__info__link:hover, .post__wrapper--full .post__info__link a:hover, .full-post a:hover, a.more:hover, .form__link a:hover, .post__description a:hover, .tel a:hover, .place a:hover, .brand__nominant a:hover, .options1 a:hover, .bt.right:hover, :hover[data-readmore-toggle], .map_obj_info a name:hover, .order_info a:hover, .review-links a:hover, .banket-name-brand:hover, .link-effect:focus, .span-wrapper .span-line a:focus, .post__wrapper--full .post__info__link:focus, .post__wrapper--full .post__info__link a:focus, .full-post a:focus, a.more:focus, .form__link a:focus, .post__description a:focus, .tel a:focus, .place a:focus, .brand__nominant a:focus, .options1 a:focus, .bt.right:focus, :focus[data-readmore-toggle], .map_obj_info a name:focus, .order_info a:focus, .review-links a:focus, .banket-name-brand:focus {
    color: #e33232; }

.link-effect-simple, .post__title a, #soclist li a i, .left-fixed-menu li a span, .video__link, .article__title, .b-header__title, .ext__row a {
  transition: color 0.2s; }
  .link-effect-simple:hover, .post__title a:hover, #soclist li a i:hover, .left-fixed-menu li a span:hover, .video__link:hover, .article__title:hover, .b-header__title:hover, .ext__row a:hover, .link-effect-simple:focus, .post__title a:focus, #soclist li a i:focus, .left-fixed-menu li a span:focus, .video__link:focus, .article__title:focus, .b-header__title:focus, .ext__row a:focus {
    color: #e33232; }

.link-reverse, .controls-link, .ava-controls a, .other-city a, .generator, .select-extender a, .brand__visit a, .show-all-link {
  transition: color 0.2s;
  color: #1ba6de; }
  .link-reverse:hover, .controls-link:hover, .ava-controls a:hover, .other-city a:hover, .generator:hover, .select-extender a:hover, .brand__visit a:hover, .show-all-link:hover, .link-reverse:focus, .controls-link:focus, .ava-controls a:focus, .other-city a:focus, .generator:focus, .select-extender a:focus, .brand__visit a:focus, .show-all-link:focus {
    color: #4dc9fb; }

.object-description .l-site--left {
  visibility: visible !important; }

.menu-delivery .l-site--left, .restaurant .l-site--left, .vse-zavedeniya .l-site--left, .companies .l-site--left, .encyclopedia .l-site--left, .restaurants-menu .l-site--left {
  visibility: hidden; }

.clearfix {
  clear: both; }

.floatfix {
  float: none !important; }

.fill-form {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto -32px auto; }

.form-submit-center {
  float: none;
  text-align: center;
  display: block;
  margin: 16px auto 0 auto; }

.cart-item, .cart__col--name, .cart__col--name_flex, .cart__col--content, .cart__col--portion, .cart__col--price {
  word-wrap: break-word; }

.banket-title {
  padding: 0 16px; }

.banket-name-brand {
  font-size: 20px; }

.banket-rating {
  padding: 0 8px; }

.banket-value {
  color: #1ba6de; }

.banket-right-block {
  float: right;
  padding-top: 4px; }

.receipt-order {
  padding: 0 16px; }
  .receipt-order p {
    padding-top: 8px;
    padding: 8px; }
